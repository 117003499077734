import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import CardsSelect from "~/components/elements/CardsSelect";
import { UserContext } from "~/contexts/user-context";
import Button from "~/components/elements/Button";

export default function Goal() {
  const { t } = useTranslation();

  const { organization, updateOrganization } = useContext(UserContext);

  const [goal, setGoal] = useState(organization.onboarding_info.goal || []);

  const goalsOptions = [
    {
      value: "ads_performance",
      label: t("onboarding.goals.ads_performance"),
      icon: "fire",
    },
    {
      value: "integrate_instagram",
      label: t("onboarding.goals.integrate_instagram"),
      icon: "code",
    },
    {
      value: "send_campaigns",
      label: t("onboarding.goals.send_campaigns"),
      icon: "paper-plane",
    },
  ];

  const handleSave = () => {
    if (!goal) return;

    updateOrganization({
      onboarding_info: { ...organization.onboarding_info, goal },
    });
  };

  return (
    <div className="max-w-[912px] mx-auto space-y-8">
      <div className="space-y-4">
        <div className="font-black text-3xl sm:text-5xl text-headings whitespace-pre-line">
          {t("onboarding.goals.title")}
        </div>
        <div className="leading-6 text-muted">
          {t("onboarding.goals.description")}
        </div>
      </div>
      <div className="space-y-4">
        <CardsSelect
          label={t("onboarding.goals.goals_label")}
          options={goalsOptions}
          value={goal}
          onChange={setGoal}
        />
        <div className="flex justify-end">
          <Button
            label={t("shared.continue")}
            disabled={!goal}
            style="primary"
            onClick={handleSave}
          />
        </div>
      </div>
    </div>
  );
}
