import React from "react";
import { PlayIcon } from "@heroicons/react/24/solid";
import { HeartIcon } from "@heroicons/react/20/solid";

export default function ContactMedium(props) {
  const { medium, onClick } = props;

  const { message_type, image_url, video_preview_url, love } = medium;

  return (
    <>
      <div className="aspect-square relative overflow-hidden" onClick={onClick}>
        <div className="bg-light-gray absolute inset-0 animate-pulse z-0" />
        <img
          src={message_type == "video" ? video_preview_url : image_url}
          className="absolute inset-0 w-full h-full object-cover object-center z-10"
        />
        {message_type == "video" && (
          <PlayIcon className="w-8 text-white absolute inset-0 m-auto drop-shadow-md z-20" />
        )}
        {love && (
          <HeartIcon className="w-4 text-white drop-shadow-sm absolute bottom-2 left-2 z-20" />
        )}
      </div>
    </>
  );
}
