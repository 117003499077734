import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import { FlowContext } from "~/contexts/flow-context";
import CustomNode from "./CustomNode";
import TriggerItem from "../triggers/TriggerItem";
import AddTrigger from "../triggers/AddTrigger";

export default function TriggerNode() {
  const { t } = useTranslation();

  const { triggers } = useContext(FlowContext);

  return (
    <CustomNode first noEdit>
      <div className="pt-3 px-4 pb-4 bg-white border-2 border-automation rounded-2xl nodrag flex flex-col space-y-3">
        <div className="text-lg font-bold">
          {t(`automation.scenarios.trigger_node.automated_title`)}
        </div>
        <div className="space-y-4">
          {triggers
            ?.filter((trigger) => !trigger._destroy)
            .map((trigger) => (
              <TriggerItem key={trigger.id} trigger={trigger} />
            ))}
        </div>
        <AddTrigger />
      </div>
    </CustomNode>
  );
}
