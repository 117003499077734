import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useCopyToClipboard, useLongPress } from "react-use";

import AppointmentMessage from "../messages/AppointmentMessage";
import ImageMessage from "../messages/ImageMessage";
import LoveReaction from "../messages/LoveReaction";
import PaymentConfirmationMessage from "../messages/PaymentConfirmationMessage";
import PaymentLinkMessage from "../messages/PaymentLinkMessage";
import Share from "../messages/Share";
import StoryMention from "../messages/StoryMention";
import StoryReply from "../messages/StoryReply";
import TextMessage from "../messages/TextMessage";
import CheckoutLinkMessage from "../messages/CheckoutLinkMessage";
import ConversionLinkMessage from "../messages/ConversionLinkMessage";
import VideoMessage from "../messages/VideoMessage";
import AudioMessage from "../messages/AudioMessage";
import Unsupported from "../messages/Unsupported";
import DoubleClick from "~/components/utils/DoubleClick";
import PopoverMenu from "~/components/utils/PopoverMenu";
import Spinner from "~/components/utils/Spinner";

import reactNativeMessage from "~/utils/reactNativeMessage";

import {
  ArrowUturnLeftIcon,
  BoltIcon,
  ClockIcon,
  DocumentDuplicateIcon,
  EllipsisHorizontalIcon,
  HeartIcon,
} from "@heroicons/react/20/solid";
import { BookmarkIcon as BookmarkIconOutline } from "@heroicons/react/24/outline";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { BookmarkIcon as BookmarkIconSolid } from "@heroicons/react/24/solid";
import { compact, delay } from "lodash";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { InboxContext } from "~/contexts/inbox-context";
import CommentBubble from "../comments/CommentBubble";
import InlineTooltip from "~/components/shared/InlineTooltip";
import { UIContext } from "~/contexts/ui-context";
import { isDev } from "~/utils/environment";
import TemplateMessage from "../messages/TemplateMessage";
import Dropdown from "~/components/elements/Dropdown";
import classNames from "~/utils/classNames";
import Button from "~/components/elements/Button";

const NEEDS_LOADING = ["image", "video", "story_mention", "story_reply"];

export default function Message(props) {
  const messageRef = useRef(null);

  const { contact, message, setMessage, last, isAnchor, highlight } = props;

  const {
    id,
    received,
    received_at,
    seen,
    content,
    automated,
    message_type,
    attachment_url,
    attachment_id,
    share_url,
    image_url,
    image_dimensions,
    video_url,
    video_preview_url,
    video_dimensions,
    audio_url,
    love,
    pending,
    scheduled,
    scheduled_for,
    comment,
    facebook_error,
    facebook_error_message,
  } = message;

  const { t } = useTranslation();

  const { showMenu, showPrompt } = useContext(UIContext);
  const {
    updateMessage,
    loadMessage,
    retryMessage,
    triggerMessageAutomation,
    destroyMessage,
  } = useContext(InboxContext);

  const refreshPendingMessage = async () => {
    const message = await loadMessage(id);
    setMessage(message);
    if (message.pending) setTimeout(refreshPendingMessage, 1000);
  };

  useEffect(() => {
    if (pending) refreshPendingMessage();
    if (NEEDS_LOADING.includes(message_type)) loadMessage(id);
  }, []);

  useEffect(() => {
    if (isAnchor) {
      delay(
        () =>
          messageRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "center",
          }),
        50,
      );
    }
  }, [isAnchor]);

  const renderBubble = (message_type) => {
    switch (message_type) {
      case "image":
        return (
          <ImageMessage
            url={image_url}
            received={received}
            dimensions={image_dimensions}
            clickable
          />
        );
      case "video":
        return (
          <VideoMessage
            url={video_url}
            preview_url={video_preview_url}
            received={received}
            dimensions={video_dimensions}
            clickable
          />
        );
      case "audio":
        return <AudioMessage url={audio_url} received={received} />;
      case "story_mention":
        return (
          <StoryMention
            url={attachment_url}
            id={attachment_id}
            contact={contact}
            received={received}
          />
        );
      case "story_reply":
        return (
          <StoryReply
            url={attachment_url}
            id={attachment_id}
            contact={contact}
            received={received}
            text={content}
          />
        );
      case "share":
        return (
          <Share
            url={image_url || share_url}
            received={received}
            contact={contact}
          />
        );
      case "survey":
        return (
          <TemplateMessage received={received} template={message.template} />
        );
      case "payment_link":
        return <PaymentLinkMessage received={received} message={message} />;
      case "payment_confirmation":
        return (
          <PaymentConfirmationMessage received={received} message={message} />
        );
      case "appointment":
        return <AppointmentMessage received={received} message={message} />;
      case "checkout_link":
        return <CheckoutLinkMessage received={received} message={message} />;
      case "conversion_link":
        return <ConversionLinkMessage received={received} message={message} />;
      case "unsupported":
        return <Unsupported contact={contact} received={received} />;
      default:
        return (
          <TextMessage
            text={content}
            received={received}
            highlight={highlight}
          />
        );
    }
  };

  // Actions

  const toggleLove = async () => {
    // Vibrate the device (React native & web)
    reactNativeMessage({ haptic: true });
    if (window.navigator.vibrate) window.navigator.vibrate(200);
    // Toggle message status
    const updatedMessage = await updateMessage(id, { love: !love });
    setMessage({ ...message, ...updatedMessage });
  };

  const [state, copyToClipboard] = useCopyToClipboard();
  const copyMessage = () => {
    copyToClipboard(content);
  };

  const handleTriggerAutomation = useCallback(async () => {
    showPrompt(t("inbox.conversation.trigger_automation_confirm"), async () => {
      const res = await triggerMessageAutomation(id);
      if (res) setMessage(res);
    });
  }, [id]);

  const actions = compact([
    {
      label: love
        ? t("inbox.conversation.unlike")
        : t("inbox.conversation.like"),
      onClick: toggleLove,
      icon: HeartIcon,
    },
    content
      ? {
          label: t("inbox.conversation.copy"),
          onClick: copyMessage,
          icon: DocumentDuplicateIcon,
        }
      : null,
    {
      label: t("inbox.conversation.trigger_automation"),
      onClick: handleTriggerAutomation,
      icon: BoltIcon,
    },
  ]);

  // Error actions

  const handleRetry = useCallback(async () => {
    const message = await retryMessage(id);
    if (message) setMessage(message);
  }, [id]);

  const handleCancel = useCallback(async () => {
    const res = await destroyMessage(id);
    if (res) setMessage(null);
  }, [id]);

  const showErrorMessageMenu = useCallback(() => {
    showMenu({
      title: t("inbox.message_error"),
      actions: [
        {
          label: t("shared.retry"),
          action: handleRetry,
        },
        {
          label: t("shared.delete"),
          className: "text-red font-medium",
          action: handleCancel,
        },
      ],
    });
  }, [showMenu, handleRetry, handleCancel, id]);

  return (
    <div
      className={classNames(
        "flex flex-col group/message",
        received ? "items-start" : "items-end",
        love ? "!mb-2" : "",
      )}
      id={`message-${id}`}
      ref={messageRef}
    >
      {comment && (
        <>
          <div className={`text-xs text-neutral-300 mb-1 mt-4`}>
            {t("inbox.conversation.replying_to")}
          </div>
          <div className="flex items-center space-x-2 mb-2">
            <CommentBubble text={comment.text} />
            <ArrowUturnLeftIcon className="w-4 text-neutral-300" />
          </div>
        </>
      )}
      <div
        className={`flex justify-items-start items-center space-x-2  ${
          !received && "flex-row-reverse space-x-reverse"
        }`}
      >
        {/* Useful for group conversations in the future */}
        {/* {received && (
        <div className="w-5 h-5 self-end rounded-full overflow-hidden flex-shrink-0">
          {last && <img src={contact.profile_picture} />}
        </div>
      )} */}

        {pending && <Spinner className="text-neutral-300 w-4 h-4" />}

        {facebook_error && (
          <InlineTooltip align="right" text={facebook_error_message}>
            <ExclamationCircleIcon
              className="w-6 text-red-500 cursor-pointer"
              onClick={showErrorMessageMenu}
            />
          </InlineTooltip>
        )}

        {scheduled && scheduled_for && (
          <InlineTooltip
            align="right"
            text={DateTime.fromISO(scheduled_for).toLocaleString(
              DateTime.DATETIME_FULL,
            )}
          >
            <ClockIcon className="w-4 text-neutral-300" />
          </InlineTooltip>
        )}

        <div
          className={`group flex items-center space-x-2  ${
            !received && "flex-row-reverse space-x-reverse"
          }`}
        >
          <div className="relative">
            <DoubleClick action={!love ? toggleLove : null}>
              {renderBubble(message_type)}
              {love && <LoveReaction className="absolute left-2 -bottom-3.5" />}
            </DoubleClick>
          </div>

          {automated && <BoltIcon className="w-4 text-automation" />}

          <Dropdown actions={actions} wrapperClassName="h-6">
            <div className="p-1 group-hover/message:opacity-100 opacity-0 transition-opacity">
              <EllipsisHorizontalIcon className="w-4 text-gray-400" />
            </div>
          </Dropdown>

          {received_at && (
            <div className="hidden sm:block opacity-0 group-hover/message:opacity-100 transition-opacity text-xs text-neutral-300 pointer-events-none select-none px-1 py-1">
              {DateTime.fromISO(received_at)?.toLocaleString(
                DateTime.TIME_24_SIMPLE,
              )}
            </div>
          )}

          {isDev && (
            <div className="text-xs text-neutral-300 opacity-0 group-hover/message:opacity-100 transition-opacity">
              #{id}
            </div>
          )}
        </div>
      </div>

      {last && seen ? (
        <div className="text-xs text-neutral-300 text-right mt-3 mr-2">
          {t("inbox.conversation.seen")}
        </div>
      ) : null}
    </div>
  );
}
