import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomNode from "./CustomNode";
import { FlowContext } from "~/contexts/flow-context";
import { delay } from "lodash";

export default function MessageNode(props) {
  const { t } = useTranslation();

  const { editAction } = useContext(FlowContext);

  const { data, selected } = props;

  const { id, content, editable = false } = data;

  const [localContent, setLocalContent] = useState(content);

  const messageRef = useRef(null);

  const handleChange = (text) => {
    editAction(id, { content: text, editable: false });
    window.getSelection?.()?.empty();
  };

  useEffect(() => {
    if (editable) delay(() => messageRef.current.focus(), 50);
  }, [editable]);

  useEffect(() => setLocalContent(content), [content]);

  return (
    <CustomNode selected={selected} help="https://youtu.be/OYhhlWwnC_w">
      <div className={`relative ${selected || editable ? "z-50" : null}`}>
        <div
          className={`text-message !p-0 min-h-[40px] whitespace-pre-wrap ring-offset-1 ring-indigo-400 ring-opacity-0 hover:ring-opacity-100 ${
            selected || editable ? "ring-opacity-100" : null
          } ${editable ? "ring" : "ring-1"} ${
            localContent?.length == 0 && "min-w-[160px]"
          }`}
        >
          {localContent?.length > 0 ? null : (
            <div className="px-4 py-2.5 absolute inset-0 text-gray-400 italic whitespace-nowrap z-0">
              {t("automation.scenarios.actions.message.placeholder")}
            </div>
          )}
          <div
            ref={messageRef}
            className={`px-4 py-2.5 outline-none select-none nodrag nopan relative z-10 ${
              editable ? "cursor-text" : null
            }`}
            contentEditable={editable}
            onInput={(e) => setLocalContent(e.target.innerText)}
            onBlur={(e) => handleChange(e.target.innerText)}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      </div>
    </CustomNode>
  );
}
