import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { FlowContext } from "~/contexts/flow-context";
import CustomNode from "./CustomNode";
import Input from "~/components/elements/Input";
import FolderPickerField from "../../../inbox/folders/FolderPickerField";

const conditionActions = ["stop", "continue"];
const conditionTypes = [
  "link_clicked",
  "replied",
  "is_follower",
  "is_in_folder",
  "message_contains",
];
const conditionOptions = ["emojis", "keywords"];

export default function ScenarioConditionNode(props) {
  const { t } = useTranslation();

  const { selected, data } = props;
  const { id, options } = data;

  const {
    condition_action,
    condition_type,
    condition_option,
    condition_value,
    folder_id,
  } = options;

  const { editAction } = useContext(FlowContext);

  // This function generates a function
  const handleChangeAction = (option) => (value) => {
    editAction(id, { options: { ...options, [option]: value } });
  };

  return (
    <CustomNode selected={selected}>
      <div
        className={`py-3 px-3 bg-white border-2 rounded-2xl nodrag ring-1 ring-indigo-400 ring-offset-1 ring-opacity-0 hover:ring-opacity-100 transition-all ${
          selected ? "ring-opacity-100 z-50" : null
        }`}
      >
        <div className="text-md font-medium mb-2">
          {t("automation.scenarios.actions.scenario_condition.title")}
        </div>
        <div className="text-2sm text-darker-gray mb-2">
          {t("automation.scenarios.actions.scenario_condition.description")}
        </div>
        <div className="flex space-x-2 items-center">
          <select
            className="input-select"
            value={condition_action}
            onChange={(evt) =>
              handleChangeAction("condition_action")(evt.target.value)
            }
          >
            {conditionActions.map((action) => (
              <option key={action} value={action}>
                {t("automation.scenarios.actions.scenario_condition." + action)}
              </option>
            ))}
          </select>
          <select
            className="input-select"
            value={condition_type}
            onChange={(evt) =>
              handleChangeAction("condition_type")(evt.target.value)
            }
          >
            {conditionTypes.map((type) => (
              <option key={type} value={type}>
                {t("automation.scenarios.actions.scenario_condition." + type)}
              </option>
            ))}
          </select>
        </div>
        {condition_type === "is_in_folder" && (
          <div className="mt-2 flex items-center justify-start space-x-2">
            <div className="text-2sm">
              {t("automation.scenarios.actions.scenario_condition.folder")}
            </div>
            <FolderPickerField
              value={folder_id}
              onPick={handleChangeAction("folder_id")}
            />
          </div>
        )}
        {condition_type === "message_contains" && (
          <div className="flex space-x-2 items-center mt-2">
            <select
              className="input-select"
              value={condition_option}
              onChange={(evt) =>
                handleChangeAction("condition_option")(evt.target.value)
              }
            >
              {conditionOptions.map((option) => (
                <option key={option} value={option}>
                  {t(
                    "automation.scenarios.actions.scenario_condition.condition_options." +
                      option,
                  )}
                </option>
              ))}
            </select>
            {condition_option == "keywords" && (
              <Input
                placeholder={t(
                  "automation.scenarios.actions.scenario_condition.message_contains_placeholder",
                )}
                value={condition_value}
                onChange={handleChangeAction("condition_value")}
                className="flex-grow"
              />
            )}
          </div>
        )}
      </div>
    </CustomNode>
  );
}
