export const isApp = navigator.userAgent.includes("MobileApp");

export const appInset = {
  top: isApp ? parseInt(navigator.userAgent.match(/top=(\d+)/)[1]) : 0,
  bottom: isApp ? parseInt(navigator.userAgent.match(/bottom=(\d+)/)[1]) : 0,
};

export const tabBarHeight = 64;
export const desktopSidebarWidth = 288;

export const isDev = process.env.RAILS_ENV === "development";
