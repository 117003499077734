import { CubeIcon } from "@heroicons/react/20/solid";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { FlowContext } from "~/contexts/flow-context";
import CheckoutLinkModal from "../../../checkout/CheckoutLinkModal";
import CheckoutLinkMessage from "../../../messages/CheckoutLinkMessage";
import AutoDelay from "./AutoDelay";
import CustomNode from "./CustomNode";

export default function ShopifyNode(props) {
  const { t } = useTranslation();

  const { editAction } = useContext(FlowContext);

  const { data, selected } = props;

  const { id, editable = false, options } = data;

  const products = options?.products || [];

  const handlePickProducts = (products) => {
    console.log({ products });
    editAction(id, { options: { products }, editable: false });
  };

  return (
    <CustomNode selected={selected}>
      <div className={`relative ${selected || editable ? "z-50" : null}`}>
        {products.length > 0 ? (
          <CheckoutLinkMessage
            message={{ checkout_link: { products } }}
            className={`ring-1 ring-offset-1 ring-indigo-400 ring-opacity-0 hover:ring-opacity-100 ${
              selected ? "ring-opacity-100" : null
            }`}
          />
        ) : (
          <div
            className="py-3 px-4 bg-white border-2 border-medium-gray text-dark-gray border-dashed rounded-2xl cursor-pointer nodrag opacity-75 hover:opacity-100 transition-opacity flex space-x-2 items-center"
            onClick={() => editAction(id, { editable: true })}
          >
            <CubeIcon className="w-4" />
            <div className="text-md font-medium text-center">
              {t("automation.scenarios.actions.checkout_link.placeholder")}
            </div>
          </div>
        )}
      </div>
      {editable && (
        <CheckoutLinkModal
          onClose={() => editAction(id, { editable: false })}
          onConfirm={handlePickProducts}
          confirmLabel={t("shared.confirm")}
        />
      )}
    </CustomNode>
  );
}
