import { ChevronRightIcon, LinkIcon } from "@heroicons/react/20/solid";
import { truncate } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function ConversionLinkItem(props) {
  const { t } = useTranslation();

  const { conversionLink } = props;

  const { id, title, url, icon_url } = conversionLink;

  return (
    <Link
      className="flex items-center justify-between p-3 sm:p-4 border-b sm:border sm:bg-lighter-gray hover:bg-light-gray transition-colors sm:rounded-2xl"
      to={`/automation/conversion_links/${id}`}
    >
      <div className="flex items-center space-x-3">
        {icon_url ? (
          <img src={icon_url} className="w-6 object-center object-contain" />
        ) : (
          <div className="p-1">
            <LinkIcon className="w-4 text-darker-gray" />
          </div>
        )}
        <div className="font-medium">{title}</div>
        <div
          className="hidden sm:block border bg-white rounded px-1 py-0.5 text-2sm font-mono"
          title={url}
        >
          {truncate(url, { length: 30 })}
        </div>
      </div>
      <div className="flex-shrink-0 flex items-center space-x-4 sm:space-x-16">
        <div className="flex items-center space-x-4 sm:space-x-6">
          {["sent", "clicked"].map((metric) => (
            <div
              key={metric}
              className="flex flex-col items-center space-y-0.5"
            >
              <div className="text-2sm text-darker-gray">
                {t(`conversion_links.metrics.${metric}`)}
              </div>
              <div className="text-md font-medium">
                {conversionLink[metric]}
              </div>
            </div>
          ))}
        </div>
        <ChevronRightIcon className="w-6 text-darker-gray" />
      </div>
    </Link>
  );
}
