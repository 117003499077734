import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import { every } from "lodash";
import { CampaignsContext } from "~/contexts/campaigns-context";
import Button from "~/components/elements/Button";
import Input from "~/components/elements/Input";
import { useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import CardsSelect from "~/components/elements/CardsSelect";
import { PlayCircleIcon, XMarkIcon } from "@heroicons/react/20/solid";

export default function NewCampaignDialog(props) {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { onClose } = props;

  const { createCampaign, campaignTemplates, loadCampaignTemplates } =
    useContext(CampaignsContext);

  const [campaign, setCampaign] = useState({});
  const [loading, setLoading] = useState(false);

  const isValid = every([campaign.title, campaign.template_id]);

  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
    setTimeout(onClose, 250);
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setLoading(true);

    if (!isValid) return;

    createCampaign(campaign).then((newCampaign) => {
      if (newCampaign) {
        setLoading(false);
        navigate(`/campaigns/${newCampaign.id}`);
        handleClose();
      }
    });
  };

  useEffect(() => setTimeout(() => setIsOpen(true), 50), []);
  useEffect(loadCampaignTemplates, []);
  const cardOptions = useMemo(() => {
    return [
      ...campaignTemplates.map((template) => {
        return {
          value: template.id,
          label: template.title,
          description: template.template_description,
          icon: template.template_icon || "bolt",
        };
      }),
      {
        value: "new",
        label: t("campaigns.templates.empty_campaign"),
        description: t("campaigns.templates.empty_campaign_description"),
        icon: "document_plus",
      },
    ];
  }, [campaignTemplates]);

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog
        onClose={handleClose}
        className={`fixed inset-0 flex flex-col items-center justify-center p-4 select-none`}
        style={{
          zIndex: 1000,
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="transition-opacity"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={`absolute inset-0 bg-black/30`}
            onClick={handleClose}
          />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="transition-all transform-gpu"
          enterFrom="scale-95 opacity-0"
          enterTo="scale-100 opacity-100"
          leave="transition-all"
          leaveFrom="scale-100 opacity-100"
          leaveTo="scale-95 opacity-0"
        >
          <Dialog.Panel
            className={`w-full max-w-2xl bg-white rounded-xl shadow relative`}
          >
            <div className="font-medium flex items-center justify-between text-lg border-b p-4">
              <div className="flex items-center">
                <PlayCircleIcon className="w-6 h-6 text-purple mr-2" />
                {t("campaigns.templates.new")}
              </div>
              <XMarkIcon
                className="w-6 h-6 text-gray-800 ml-auto cursor-pointer"
                onClick={handleClose}
              />
            </div>
            <div className="flex-grow py-4 px-4 space-y-4">
              <div className="space-y-1">
                <div className="text-base font-semibold leading-6 text-gray-900">
                  {t("campaigns.templates.title_label")}
                </div>
                <Input
                  placeholder={t("campaigns.templates.title_placeholder")}
                  name="title"
                  value={campaign?.title}
                  onChange={(value) =>
                    setCampaign({ ...campaign, title: value })
                  }
                  className="w-1/2"
                  autoFocus
                />
              </div>
              <CardsSelect
                label={t("campaigns.templates.choose_template")}
                options={cardOptions}
                cols={3}
                onChange={(value) =>
                  setCampaign({ ...campaign, template_id: value })
                }
              />
            </div>
            {/* reversing flow to click on first button when hitting Enter key */}
            <div className="border-t p-4 flex flex-row-reverse items-center justify-between">
              <Button
                label={t("campaigns.templates.create_campaign")}
                style="primary"
                type="submit"
                onClick={handleSubmit}
                disabled={!isValid}
                loading={loading}
              />
              <Button label={t("shared.cancel")} onClick={handleClose} />
            </div>
          </Dialog.Panel>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
}
