import React, { useContext } from "react";
import calendlyIcon from "../../../../assets/images/calendly_icon.png";
import { useTranslation } from "react-i18next";
import { UserContext } from "~/contexts/user-context";
import ExternalAccount from "./ExternalAccount";
import Button from "~/components/elements/Button";

export default function CalendlyAccount() {
  const { organization } = useContext(UserContext);

  const { calendly_connected, calendly_name, calendly_email } = organization;

  const { t } = useTranslation();

  return (
    <ExternalAccount
      icon={calendlyIcon}
      provider="calendly"
      connected={calendly_connected}
      name={calendly_name}
      subname={calendly_email}
      refresh
      connect={
        <Button
          label={t("profile.external_accounts.calendly.connect")}
          className="w-full block"
          href="/users/auth/calendly"
        />
      }
    />
  );
}
