import React, { useContext } from "react";

import logo from "../../../assets/images/logo_color.svg";
import { useTranslation } from "react-i18next";
import classNames from "~/utils/classNames";
import { ArrowPathRoundedSquareIcon } from "@heroicons/react/24/outline";
import { UserContext } from "~/contexts/user-context";

const steps = ["connect_instagram", "about_you", "goal", "free_trial"];

export default function OnboardingSidebar() {
  const { t } = useTranslation();

  const { organization, switchOrganization } = useContext(UserContext);

  const currentStep = organization.onboarding_step;

  return (
    <div className="hidden sm:flex flex-col justify-between space-y-10 w-[260px] lg:w-[320px] flex-shrink-0 h-screen overflow-hidden bg-black p-6">
      <img src={logo} className="w-[128px] h-autp" />
      <div className="flex-grow">
        <div className="pl-2.5 font-bold text-xs leading-5 uppercase text-white/70 mb-2">
          {t("onboarding.navigation.title")}
        </div>
        {steps.map((step, index) => (
          <div
            key={step}
            className={classNames(
              "p-2.5 space-x-2 flex items-start rounded-lg select-none",
              currentStep == step
                ? "bg-white text-black"
                : "text-white opacity-50",
            )}
          >
            <div
              className={classNames(
                "m-0.5 rounded-full w-4 h-4 text-center leading-4 text-2xs font-medium",
                currentStep == step
                  ? "bg-black text-white"
                  : "bg-white text-black",
              )}
            >
              {index + 1}
            </div>
            <div className="flex-grow">
              <div
                className={classNames(
                  "leading-5",
                  currentStep == step && "font-medium",
                )}
              >
                {t(`onboarding.navigation.${step}.title`)}
              </div>
              <div className="text-xs leading-4 opacity-70">
                {t(`onboarding.navigation.${step}.description`)}
              </div>
            </div>
            <div
              className={classNames(
                "hidden lg:block px-1 rounded text-xs leading-5 font-medium",
                currentStep == step ? "bg-black/5" : "bg-white/20",
              )}
            >
              {t(`onboarding.navigation.${step}.time`)}
            </div>
          </div>
        ))}
      </div>
      <div className="w-full flex items-center gap-2 overflow-hidden">
        <a
          href="/sign_out"
          className="block px-3 py-2 flex-grow rounded-lg text-sm text-white text-center hover:text-black hover:bg-white"
        >
          {t("profile.log_out")}
        </a>
        <button
          className={` px-3 py-2 flex-grow rounded-lg text-sm text-white text-center hover:text-black hover:bg-white`}
          onClick={switchOrganization}
        >
          {t("profile.switch_organization")}
        </button>
      </div>
    </div>
  );
}
