import {
  AtSymbolIcon,
  CameraIcon,
  ChatBubbleBottomCenterIcon,
  ChatBubbleLeftEllipsisIcon,
  ChatBubbleLeftRightIcon,
  ListBulletIcon,
  ClockIcon,
  FolderIcon,
  MicrophoneIcon,
  HeartIcon,
  LinkIcon,
  PhotoIcon,
  CodeBracketIcon,
  FunnelIcon,
  CheckBadgeIcon,
  FlagIcon,
  EyeSlashIcon,
} from "@heroicons/react/20/solid";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import { FlowContext } from "~/contexts/flow-context";
import triggerTypeOptions from "./triggers/triggerTypeOptions";
import Modal from "~/components/shared/Modal";
import ActionTypeMenuItem from "./ActionTypeMenuItem";
import ShopifyIcon from "~/components/shared/icons/ShopifyIcon";
import { UserContext } from "~/contexts/user-context";
import { compact, keys } from "lodash";

export default function ActionTypeMenu(props) {
  const { t } = useTranslation();

  const { onSubmit, onClose } = props;

  const { organization } = useContext(UserContext);
  const { triggers } = useContext(FlowContext);

  const disabledActions =
    triggerTypeOptions[triggers?.[0]?.trigger_type]?.disabled_actions || [];

  const enabledActions =
    triggerTypeOptions[triggers?.[0]?.trigger_type]?.enabled_actions || [];

  const checkDisabled = (actionType) => {
    if (enabledActions.length) {
      return !enabledActions.includes(actionType);
    } else if (disabledActions.length) {
      return disabledActions.includes(actionType);
    } else {
      return false;
    }
  };

  const conversation = [
    {
      action_type: "message",
      type: "Actions::Message",
      icon: ChatBubbleBottomCenterIcon,
      editable: true,
      content: "",
    },
    {
      action_type: "like",
      type: "Actions::Like",
      icon: HeartIcon,
    },
    {
      action_type: "media_message",
      type: "Actions::MediaMessage",
      icon: CameraIcon,
      editable: true,
      content: "",
      options: {
        media_file_id: null,
      },
    },
    {
      action_type: "share_media",
      type: "Actions::ShareMedia",
      icon: PhotoIcon,
      editable: true,
      content: "",
    },
    {
      action_type: "audio_message",
      type: "Actions::AudioMessage",
      icon: MicrophoneIcon,
      editable: true,
      options: {
        media_file_id: null,
      },
    },
    {
      action_type: "survey",
      type: "Actions::Survey",
      icon: ListBulletIcon,
      editable: true,
      options: {
        survey_id: null,
      },
    },
    {
      action_type: "conversion_link",
      type: "Actions::ConversionLink",
      icon: LinkIcon,
      editable: true,
      options: {
        conversion_link_id: null,
      },
    },
    organization.features.ecommerce && {
      action_type: "checkout_link",
      type: "Actions::CheckoutLink",
      icon: ShopifyIcon,
      editable: true,
      options: {
        products: [],
      },
    },
    organization.features.payments && {
      action_type: "payment_link",
      type: "Actions::PaymentLink",
      icon: LinkIcon,
      editable: true,
      payment_link_id: null,
    },
  ];

  const comments = [
    {
      action_type: "comment",
      type: "Actions::Comment",
      icon: ChatBubbleLeftRightIcon,
      editable: true,
      content: "",
    },
    {
      action_type: "hide_comment",
      type: "Actions::HideComment",
      icon: EyeSlashIcon,
    },
  ];

  const logic = [
    {
      action_type: "delay",
      type: "Actions::Delay",
      icon: ClockIcon,
      options: {
        autoFocus: true,
        delay_duration: 60,
        delay_unit: "seconds",
      },
    },
    {
      action_type: "wait_for_reply",
      type: "Actions::WaitForReply",
      icon: ChatBubbleLeftEllipsisIcon,
      options: {
        wait_action: "stop_scenario",
        expire_duration: 3,
        expire_unit: "days",
      },
    },
    {
      action_type: "folder",
      type: "Actions::Folder",
      icon: FolderIcon,
      menuVisible: true,
      options: {
        folder_action: "add",
        folder_id: null,
      },
    },
    {
      action_type: "collect_email",
      type: "Actions::CollectEmail",
      icon: AtSymbolIcon,
      options: {
        wait: true,
      },
    },
    {
      action_type: "scenario_condition",
      type: "Actions::ScenarioCondition",
      icon: FunnelIcon,
      options: {
        condition_action: "continue",
        condition_type: "link_clicked",
      },
    },
    organization.dev && {
      action_type: "content_filter",
      icon: FlagIcon,
      options: {
        folder_id: null,
      },
    },
    {
      action_type: "http_request",
      type: "Actions::HttpRequest",
      icon: CodeBracketIcon,
      options: {
        http_method: "GET",
        http_url: "",
        http_headers: [],
        http_params: [],
      },
    },
  ];

  const actionGroups = {
    conversation,
    comments,
    logic,
  };

  const visibleGroups = ["conversation", "comments", "logic"];

  const renderAction = (action) => (
    <ActionTypeMenuItem
      key={action.action_type}
      action={action}
      disabled={action.disabled || checkDisabled(action.action_type)}
      onClick={() => handleClickAction(action)}
    />
  );

  const handleClickAction = (action) => {
    const { icon, ...actionPayload } = action;
    onSubmit(actionPayload);
    onClose();
  };

  return (
    <Modal onClose={onClose}>
      <div className="flex flex-col space-y-4 p-3 pb-6 sm:p-4">
        {compact(visibleGroups).map((group) => (
          <div key={group}>
            <div className="font-medium text-md mb-3">
              {t(`automation.scenarios.action_groups.${group}`)}
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
              {compact(actionGroups[group]).map(renderAction)}
            </div>
          </div>
        ))}
      </div>
    </Modal>
  );
}
