const triggerTypeOptions = {
  messages: {
    options: ["any", "keywords", "only_emojis"],
    defaultOptions: {
      messages_option: "any",
      keywords: [],
    },
    disabled_actions: ["comment", "hide_comment"],
  },
  referral_ad: {
    options: ["keywords", "ad_id"],
    defaultOptions: {
      referral_ad_option: "keywords",
      keywords: [],
    },
    disabled_actions: ["comment", "hide_comment"],
  },
  story_reply: {
    // options: ["any", "specific", "caption_keyword", "keywords"],
    options: ["any", "keywords", "only_emojis"],
    defaultOptions: {
      story_reply_option: "any",
      keywords: [],
    },
    disabled_actions: ["comment", "hide_comment"],
  },
  story_mention: {
    disabled_actions: ["comment", "hide_comment"],
  },
  comment: {
    options: ["any", "specific", "caption_keyword", "keywords"],
    defaultOptions: {
      comment_option: "any",
      keywords: [],
    },
    disabled_actions: ["like"],
  },
  outgoing_message: {
    options: ["any", "keywords"],
    defaultOptions: {
      outgoing_message_option: "any",
      keywords: [],
    },
    disabled_actions: ["comment", "hide_comment"],
  },
  referral: {
    options: ["any", "ref_code"],
    defaultOptions: {
      referral_option: "any",
      referral_code: "",
      icebreaker: "",
    },
    disabled_actions: ["comment", "hide_comment"],
  },
  folder: {
    defaultOptions: {
      folder_id: null,
    },
  },
  custom_event: {
    defaultOptions: {
      custom_event_id: null,
    },
  },
  webhook: {
    options: [],
  },
};

export default triggerTypeOptions;
