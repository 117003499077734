import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import CustomNode from "./CustomNode";
import Input from "~/components/elements/Input";
import { FlowContext } from "~/contexts/flow-context";
import FolderPickerField from "../../../inbox/folders/FolderPickerField";
import ButtonGroup from "~/components/elements/ButtonGroup";

export default function WaitForReplyNode(props) {
  const { t } = useTranslation();

  const { selected, data } = props;
  const { id, options } = data;

  const {
    wait_action = "stop_scenario",
    folder_id,
    expire_duration,
    expire_unit,
  } = options;

  const { editAction } = useContext(FlowContext);

  const handleActionChange = (action) => {
    editAction(id, { options: { ...options, wait_action: action } });
  };

  const handleFolderChange = (folderId) => {
    editAction(id, { options: { ...options, folder_id: folderId } });
  };

  const handleDurationChange = (value) => {
    editAction(id, {
      options: { ...options, expire_duration: parseInt(value) },
    });
  };

  const handleUnitChange = (evt) => {
    editAction(id, {
      options: { ...options, expire_unit: evt.target.value },
    });
  };

  const actionButtons = ["stop_scenario", "continue_scenario"].map(
    (action) => ({
      label: t(`automation.scenarios.actions.wait_for_reply.${action}`),
      onClick: () => handleActionChange(action),
      size: "small",
      style: wait_action === action ? "primary" : "default",
    }),
  );

  return (
    <CustomNode selected={selected}>
      <div
        className={`p-3 bg-white border-2 rounded-2xl max-w-xs nodrag ring-1 ring-indigo-400 ring-offset-1 ring-opacity-0 hover:ring-opacity-100 transition-all ${
          selected ? "ring-opacity-100 z-50" : null
        }`}
      >
        <div className="text-md font-medium mb-2">
          {t("automation.scenarios.actions.wait_for_reply.title")}
        </div>
        <div className="space-y-3">
          <div className="text-sm font-medium">
            {t(
              "automation.scenarios.actions.wait_for_reply.when_contact_replies",
            )}
          </div>
          <ButtonGroup buttons={actionButtons} />
          {wait_action === "stop_scenario" && (
            <div className="space-y-2">
              <div className="flex items-center space-x-2">
                <div className="text-2sm">
                  {t(
                    "automation.scenarios.actions.wait_for_reply.add_to_folder",
                  )}
                </div>
                <FolderPickerField
                  value={folder_id}
                  onPick={handleFolderChange}
                />
              </div>
              <div className="text-sm font-medium">
                {t("automation.scenarios.actions.wait_for_reply.else")}
              </div>
              <div className="flex items-center space-x-2">
                <Input
                  value={expire_duration}
                  onChange={handleDurationChange}
                  type="number"
                  className="max-w-[50px]"
                />

                <select
                  className="input-select"
                  value={expire_unit}
                  onChange={handleUnitChange}
                >
                  {["seconds", "minutes", "hours", "days"].map((unit) => (
                    <option key={unit} value={unit}>
                      {t("time." + unit)}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}
        </div>
      </div>
    </CustomNode>
  );
}
