import React, { useContext } from "react";
import { ContentContext } from "~/contexts/content-context";
import { useTranslation } from "react-i18next";
import { ArrowPathIcon } from "@heroicons/react/20/solid";
import Loader from "~/components/utils/Loader";
import MediaItem from "./MediaItem";
import Modal from "~/components/shared/Modal";
import { AutomationContext } from "~/contexts/automation-context";
import { sortBy } from "lodash";

export default function MediaPickerDialog(props) {
  const { onClose, onPick = () => {} } = props;

  const { t } = useTranslation();

  const { loading, medias, refreshMedias } = useContext(ContentContext);

  const handlePick = (mediaId) => {
    onPick(mediaId);
    onClose();
  };

  const sortedMedias = sortBy(medias, "posted_at").reverse();

  return (
    <Modal onClose={onClose} zIndex={1100}>
      <div className="min-w-[400px] max-h-[600px] flex flex-col overflow-hidden">
        <div className="pt-4 px-4 pb-3 flex items-center justify-between border-b">
          <div className="font-medium text-lg">
            {t("content.medias.pick_media")}
          </div>
          {loading ? (
            <div className="p-2">
              <Loader strokeWidth={8} width={20} />
            </div>
          ) : (
            <button className="p-2" onClick={refreshMedias}>
              <ArrowPathIcon className={`w-5 text-dark-gray`} />
            </button>
          )}
        </div>
        <div className="flex-grow overflow-y-auto">
          <div className="px-3 py-3 grid grid-cols-3 gap-2">
            {sortedMedias?.map((media) => (
              <div
                className="w-48 p-2 hover:bg-grey-200 rounded-lg cursor-pointer"
                onClick={() => handlePick(media.instagram_id)}
              >
                <MediaItem key={media.id} media={media} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
}
