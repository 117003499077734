import React, { useContext } from "react";
import { FlowContext } from "~/contexts/flow-context";
import triggerTypeOptions from "./triggerTypeOptions";
import { useTranslation } from "react-i18next";
import Input from "~/components/elements/Input";
import TriggerKeywordsOption from "./TriggerKeywordsOption";
import ButtonGroup from "~/components/elements/ButtonGroup";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import Button from "~/components/elements/Button";
import { QuestionMarkCircleIcon } from "@heroicons/react/20/solid";

export default function ReferralAdOptions(props) {
  const { t } = useTranslation();

  const { trigger } = props;

  const { editTriggerOptions } = useContext(FlowContext);

  const options = triggerTypeOptions.referral_ad.options || "keywords";

  const handleOptionChange = (evt) => {
    editTriggerOptions(trigger.id, {
      referral_ad_option: evt.target.value,
    });
  };

  return (
    <div className="flex flex-col space-y-3">
      <ButtonGroup
        buttons={options.map((option) => ({
          label: t(
            "automation.scenarios.trigger_type.referral_ad.options." + option,
          ),
          style:
            option === trigger.options.referral_ad_option
              ? "primary"
              : "default",
          onClick: () =>
            editTriggerOptions(trigger.id, { referral_ad_option: option }),
        }))}
      />
      {trigger.options.referral_ad_option === "keywords" && (
        <TriggerKeywordsOption trigger={trigger} />
      )}
      {trigger.options.referral_ad_option === "ad_id" && (
        <Input
          placeholder={t(`automation.scenarios.trigger_node.ad_id`)}
          value={trigger.options.referral_ad_id}
          onChange={(referral_ad_id) =>
            editTriggerOptions(trigger.id, { referral_ad_id })
          }
        />
      )}
      <div className="bg-lighter-gray px-3 py-2 rounded-lg text-2sm">
        <ExclamationTriangleIcon className="w-4 inline-block mr-1" />
        {t("automation.scenarios.trigger_type.referral_ad.notice")}
        <Button
          icon={QuestionMarkCircleIcon}
          size="small"
          className="!flex mt-2"
          label={t("shared.view_documentation")}
          href="https://intercom.help/inro/en/articles/9464380-how-to-trigger-scenario-from-ads"
        />
      </div>
    </div>
  );
}
