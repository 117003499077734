import React, { useContext, useState, createRef, useRef } from "react";
import FullScreen from "~/components/utils/FullScreen";
import PageHeader from "~/components/shared/PageHeader";
import { useTranslation } from "react-i18next";
import { UserContext } from "~/contexts/user-context";
import RadioToggle from "../../forms/RadioToggle";
import LabeledInput from "../../forms/LabeledInput";
import { isApp } from "~/utils/environment";
import { UIContext } from "~/contexts/ui-context";
import { isEqual } from "lodash";
import { isValidPhoneNumber } from "react-phone-number-input";

export default function PaymentAccountDetails(props) {
  const { back } = props;

  const { t } = useTranslation();

  const { user, organization, updateOrganization } = useContext(UserContext);
  const { showAlert } = useContext(UIContext);

  const { stripe_account_business_type, stripe_account_details } = organization;

  const [businessType, setBusinessType] = useState(
    stripe_account_business_type || "individual",
  );
  const [details, setDetails] = useState(
    stripe_account_details || {
      country: "FR",
    },
  );

  const handleBack = () => {
    if (
      stripe_account_business_type == businessType &&
      isEqual(stripe_account_details, details)
    ) {
      back();
    } else {
      showAlert({
        title: t("shared.unsaved_changes"),
        confirm: t("shared.discard_changes"),
        confirmClassName: "text-red-500",
        cancelClassName: "text-black",
        onSubmit: back,
      });
    }
  };

  const handleSave = () => {
    if (
      (businessType == "individual" &&
        !isValidPhoneNumber(details.individual_phone || "")) ||
      (businessType == "company" &&
        !isValidPhoneNumber(details.company_phone || ""))
    ) {
      showAlert(t("payments.settings.details.invalid_phone"));
      return;
    }
    updateOrganization({
      stripe_account_business_type: businessType,
      stripe_account_details: details,
    });
  };

  const handleChange = (key) => (value) => {
    setDetails({ ...details, [key]: value });
  };

  const individualFields = [
    {
      label: t("payments.settings.details.first_name"),
      name: "individual_first_name",
      autoComplete: "given-name",
      placeholder: "Jane",
    },
    {
      label: t("payments.settings.details.last_name"),
      name: "individual_last_name",
      autoComplete: "family-name",
      placeholder: "Doe",
    },
    {
      label: t("payments.settings.details.dob"),
      name: "individual_dob",
      autoComplete: "bday",
      placeholder: "DD/MM/YYYY",
      type: "date",
    },
    {
      label: t("payments.settings.details.address"),
      name: "individual_address_line1",
      autoComplete: "address-line1",
      placeholder: "57 rue des Vinaigriers",
    },
    {
      label: t("payments.settings.details.address_details"),
      name: "individual_address_line2",
      autoComplete: "address-line2",
      placeholder: t("payments.settings.details.address_details_placeholder"),
    },
    {
      label: t("payments.settings.details.postal_code"),
      name: "individual_address_postal_code",
      autoComplete: "postal-code",
      placeholder: "75010",
    },
    {
      label: t("payments.settings.details.city"),
      name: "individual_address_city",
      autoComplete: "address-level2",
      placeholder: "Paris",
    },
    {
      label: t("payments.settings.details.phone"),
      name: "individual_phone",
      type: "tel",
      autoComplete: "tel",
      inputMode: "tel",
      placeholder: "+33 6 12 34 56 78",
    },
    // add ref for each input
  ].map((field) => ({ ...field, ref: createRef() }));

  const companyFields = [
    {
      label: t("payments.settings.details.company_name"),
      name: "company_name",
      autoComplete: "organization",
      placeholder: "PCF Inc.",
    },
    {
      label: t("payments.settings.details.address"),
      name: "company_address_line1",
      autoComplete: "address-line1",
      placeholder: "57 rue des Vinaigriers",
    },
    {
      label: t("payments.settings.details.address_details"),
      name: "company_address_line2",
      autoComplete: "address-line2",
      placeholder: t("payments.settings.details.address_details_placeholder"),
    },
    {
      label: t("payments.settings.details.postal_code"),
      name: "company_address_postal_code",
      autoComplete: "postal-code",
      placeholder: "75010",
    },
    {
      label: t("payments.settings.details.city"),
      name: "company_address_city",
      autoComplete: "address-level2",
      placeholder: "Paris",
    },
    {
      label: t("payments.settings.details.phone"),
      name: "company_phone",
      type: "tel",
      autoComplete: "tel",
      inputMode: "tel",
      placeholder: "+33 6 12 34 56 78",
    },
    // add ref for each input
  ].map((field) => ({ ...field, ref: createRef() }));

  const fields =
    businessType == "individual" ? individualFields : companyFields;

  // scroll inputs into view
  const scrollRef = useRef(null);
  const blurOnScroll = useRef(true);

  const blurAll = () => {
    if (blurOnScroll.current)
      fields.forEach((field) => field.ref.current.blur());
  };

  const scrollIntoView = (index) => {
    const input = fields[index].ref.current;
    if (input && isApp) {
      blurOnScroll.current = false;
      // scroll into view with timeout to let keyboard open
      setTimeout(() => {
        const scrollPosition = scrollRef.current.scrollTop;
        const inputY = input.offsetTop;
        const inputHeight = input.offsetHeight;
        const hiddenHeight = Math.max(
          0,
          inputY + inputHeight - scrollPosition - window.visualViewport.height,
        );
        if (hiddenHeight > 0) {
          scrollRef.current.scrollTo({
            top: scrollPosition + hiddenHeight,
            behavior: "smooth",
          });
        }
        setTimeout(() => {
          blurOnScroll.current = true;
        }, 500);
      }, 200);
    }
  };

  const handleNext = (index) => (evt) => {
    if (evt.key == "Enter") {
      if (index < fields.length - 1) {
        fields[index + 1].ref.current.focus();
      } else {
        blurAll();
      }
    }
  };

  return (
    <FullScreen className="flex flex-col">
      <PageHeader
        title={t("payments.settings.details.title")}
        className="mb-4"
        action={
          !user.stripe_account_id && {
            label: t("shared.save"),
            onClick: handleSave,
          }
        }
        back={handleBack}
      />
      <div
        className="flex-grow overflow-auto scrollbar-hide pb-96"
        onScroll={blurAll}
        ref={scrollRef}
      >
        {/* Locked info */}
        {user.stripe_account_id && (
          <div className="px-4 py-2">
            <div className="rounded-lg bg-light-primary text-2sm p-3">
              {t("payments.settings.details.locked")}
            </div>
          </div>
        )}
        {/* Business Type */}
        <div className="px-4 py-2">
          <RadioToggle
            value={businessType}
            onChange={(value) => setBusinessType(value)}
            disabled={organization.stripe_account_id}
            options={[
              {
                label: t("payments.settings.details.type_individual"),
                value: "individual",
              },
              {
                label: t("payments.settings.details.type_company"),
                value: "company",
              },
            ]}
          />
        </div>
        {/* Individual */}
        <div>
          {fields.map((field, index) => (
            <LabeledInput
              key={field.name}
              value={details[field.name]}
              onChange={handleChange(field.name)}
              enterKeyHint={index < fields.length - 1 ? "next" : "done"}
              onFocus={() => scrollIntoView(index)}
              onKeyDown={handleNext(index)}
              disabled={organization.stripe_account_id}
              {...field}
            />
          ))}
        </div>
      </div>
    </FullScreen>
  );
}
