import React, { useState, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AutomationContext } from "~/contexts/automation-context";
import Button from "~/components/elements/Button";
import { UIContext } from "~/contexts/ui-context";
import Input from "~/components/elements/Input";
import { XMarkIcon } from "@heroicons/react/20/solid";
import FolderPickerField from "../../inbox/folders/FolderPickerField";
import TemplateMessage from "../../messages/TemplateMessage";
import InputCheckbox from "~/components/elements/InputCheckbox";
import SurveyResults from "./SurveyResults";

export default function SurveyForm(props) {
  const { t } = useTranslation();

  const { saveSurvey, destroySurvey } = useContext(AutomationContext);
  const { showPrompt } = useContext(UIContext);

  const {
    handleClose,
    onSave = () => {},
    survey: initialSurvey = {
      title: "",
      subtitle: "",
      survey_options: [],
    },
  } = props;

  const [survey, setSurvey] = useState(initialSurvey);
  const [options, setOptions] = useState(initialSurvey?.survey_options || []);
  const [errorMessage, setErrorMessage] = useState(null);

  const visibleOptions = options.filter((o) => !o._destroy);

  const templatePayload = useMemo(
    () =>
      survey.title
        ? {
            title: survey.title,
            subtitle: survey.subtitle,
            buttons: visibleOptions,
          }
        : null,
    [survey, visibleOptions],
  );

  const optionResults = useMemo(
    () =>
      visibleOptions?.map((option) => ({
        title: option.title,
        replies: option.reply_count,
      })) || [],
    [survey, visibleOptions],
  );

  const handleChange = (field) => (value) => {
    setSurvey((survey) => ({ ...survey, [field]: value }));
  };

  const handleChangeOption = (index, field) => (value) => {
    setOptions((options) =>
      options.map((option, i) =>
        i === index ? { ...option, [field]: value } : option,
      ),
    );
  };

  const addOption = () => {
    setOptions((options) => [
      ...options,
      { title: "", folder_id: null, reply: "" },
    ]);
  };

  const removeOption = (index) => {
    const option = options[index];
    if (option.id) {
      setOptions((options) =>
        options.map((o) => (o.id === option.id ? { ...o, _destroy: true } : o)),
      );
    } else {
      setOptions((options) => options.filter((_, i) => i !== index));
    }
  };

  // Validation & saving

  const isOptionValid = (option) => option.title.trim().length > 0;

  const isValid =
    survey.title.trim().length > 0 &&
    visibleOptions.length > 0 &&
    visibleOptions.every(isOptionValid);

  const [loadingSave, setLoadingSave] = useState(false);

  const handleSave = async () => {
    if (!isValid) return;

    setLoadingSave(true);

    const newSurvey = await saveSurvey({
      ...survey,
      id: survey.id ? parseInt(survey.id) : null,
      title: survey.title.trim(),
      subtitle: survey.subtitle.trim(),
      survey_options_attributes: options,
    });

    setLoadingSave(false);
    handleClose();
    onSave(newSurvey);
  };

  const handleDelete = () => {
    showPrompt(t("surveys.delete_confirm"), () => {
      destroySurvey(survey.id);
      handleClose();
    });
  };

  return (
    <div className={`flex-grow flex flex-col overflow-hidden`}>
      <div className="flex flex-col sm:flex-row items-stretch overflow-hidden">
        <div className="w-96 space-y-4 p-4 overflow-y-auto">
          <Input
            label={
              survey.id ? t("surveys.edit_survey") : t("surveys.new_survey")
            }
            placeholder={t("surveys.title_placeholder")}
            value={survey.title}
            onChange={handleChange("title")}
            autoFocus={!survey.title}
            maxLength={80}
            emojiPicker
          />
          <Input
            type="textarea"
            placeholder={t("surveys.subtitle_placeholder")}
            value={survey.subtitle}
            onChange={handleChange("subtitle")}
            maxLength={80}
            emojiPicker
          />
          <InputCheckbox
            label={t("surveys.limit_replies")}
            value={survey.limit_replies}
            onClick={handleChange("limit_replies")}
            className="px-1 py-1"
          />
          {visibleOptions.map((option, index) => (
            <div
              key={index}
              className="border p-2 rounded-md relative group mb-3"
            >
              <button
                className="absolute -top-2 -right-2 w-5 h-5 flex items-center justify-center bg-white border shadow rounded-full text-darker-gray opacity-0 group-hover:opacity-100 transition-opacity"
                onClick={() => removeOption(index)}
              >
                <XMarkIcon className="w-3" />
              </button>
              <Input
                placeholder={t(`surveys.option_placeholder.${index % 3}`)}
                value={option.title}
                onChange={handleChangeOption(index, "title")}
                autoFocus={!option.title}
                emojiPicker
                maxLength={20}
                className="mb-2"
              />
              <div className="flex items-center space-x-2 mb-2 overflow-hidden">
                <div className="text-xs flex-grow whitespace-nowrap flex-shrink-0">
                  {t("surveys.add_to_folder")}
                </div>
                <FolderPickerField
                  value={option.folder_id}
                  onPick={handleChangeOption(index, "folder_id")}
                />
              </div>
              <div className="flex items-center space-x-2">
                <div className="text-xs whitespace-nowrap flex-shrink-0">
                  {t("surveys.and_reply")}
                </div>
                <Input
                  className="flex-grow"
                  placeholder={t(`surveys.reply_placeholder`)}
                  value={option.reply}
                  onChange={handleChangeOption(index, "reply")}
                  emojiPicker
                />
              </div>
            </div>
          ))}
          {visibleOptions.length < 15 && (
            <Button onClick={addOption} label={t(`surveys.add_option`)} />
          )}
        </div>
        <div className="w-full sm:w-80 py-4 sm:py-8 bg-lighter-gray hidden sm:flex flex-col space-y-4 items-center justify-center">
          {templatePayload && <TemplateMessage template={templatePayload} />}
          <div className="bg-white rounded-lg border p-3">
            <div className="text-xs font-medium text-muted mb-2">
              {t("shared.results")}
            </div>
            <SurveyResults options={optionResults} />
          </div>
        </div>
      </div>
      <div className="border-t p-4 flex items-center justify-between">
        <div className="flex space-x-2">
          <Button label={t("shared.cancel")} onClick={handleClose} />
          {survey?.id ? (
            <Button
              label={t("shared.delete")}
              onClick={handleDelete}
              style="danger"
            />
          ) : null}
        </div>
        <div className="flex items-center space-x-2">
          {errorMessage && (
            <div className="text-red text-sm text-medium">{errorMessage}</div>
          )}
          <Button
            label={t("shared.save")}
            style="primary"
            onClick={handleSave}
            disabled={!isValid}
            loading={loadingSave}
          />
        </div>
      </div>
    </div>
  );
}
