import React, { useContext, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import scenarioStatusIcon from "~/utils/scenarioStatusIcon";
import { AutomationContext } from "~/contexts/automation-context";
import SkeletonLoader from "~/components/utils/SkeletonLoader";
import ScenarioMetrics from "./ScenarioMetrics";
import { ErrorBoundary } from "react-error-boundary";
import ErrorComponent from "~/components/utils/ErrorComponent";
import ScenarioHistory from "./ScenarioHistory";
import { XMarkIcon } from "@heroicons/react/20/solid";
import ScenarioLimitReached from "./ScenarioLimitReached";

export default function ScenarioResults(props) {
  const { scenarioId, handleClose = () => {} } = props;

  const { scenarios, loadScenario } = useContext(AutomationContext);

  const scenario = useMemo(
    () =>
      scenarios.find((s) => s.id.toString() === scenarioId.toString()) || {},
    [scenarioId, scenarios],
  );

  const { active, title, loaded } = scenario;

  useEffect(() => !loaded && loadScenario(scenarioId), [scenarioId, loaded]);

  if (!scenario) {
    return (
      <div className="w-full h-full flex items-center justify-center"></div>
    );
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorComponent}>
      <div className="w-full h-full flex flex-col overflow-hidden">
        <div className="flex items-center justify-between p-4 border-b">
          {loaded ? (
            <div className="flex items-center space-x-2">
              {scenarioStatusIcon(active)}
              <div className="text-medium">{title}</div>
            </div>
          ) : (
            <SkeletonLoader width={200} height={20} />
          )}
          <button className="p-2" onClick={handleClose}>
            <XMarkIcon className="w-6" />
          </button>
        </div>
        <ScenarioMetrics scenario={scenario} />
        {/* <ScenarioCharts scenarioId={scenarioId} /> */}
        <ScenarioLimitReached scenario={scenario} />
        <ScenarioHistory scenarioId={scenarioId} />
      </div>
    </ErrorBoundary>
  );
}
