import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { appInset } from "~/utils/environment";
import { compact, groupBy, map } from "lodash";

export default function BottomMenu(props) {
  const {
    title,
    click = null,
    actions = null,
    groupActionsBy = null,
    onClose,
    isActive = true,
  } = props;

  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);

  // animate appear
  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
      showSelectedOption();
    }, 50);
  }, []);

  const handleClose = (evt) => {
    if (!visible || !isActive) return;
    setVisible(false);
    setTimeout(onClose, 150);
  };

  // Show an option on visible
  const selectedRef = useRef(null);
  const showSelectedOption = () => {
    selectedRef.current?.scrollIntoView({
      behavior: "instant",
      block: "center",
    });
  };

  const renderAction = (action, index) => {
    return (
      <button
        key={index}
        className={`flex items-center justify-center w-full ${
          groupActionsBy ? "text-left px-6" : "text-center"
        } py-3 hover:bg-neutral-50 transition-colors border-b ${
          action?.bold ? "font-semibold" : ""
        } ${action.disabled ? "text-neutral-400 pointer-events-none" : ""} ${
          action?.className
        }`}
        ref={action?.selected ? selectedRef : null}
        onClick={() => {
          if (action.disabled) return;
          handleClose();
          setTimeout(action.action, 200);
        }}
      >
        {action.label}
      </button>
    );
  };

  const renderActions = () => {
    if (groupActionsBy) {
      const groupedActions = groupBy(actions, groupActionsBy);
      return map(groupedActions, (actions, group) => (
        <div key={group}>
          <div className="py-4 px-3 text-2sm text-left text-dark-gray border-b sticky top-0 bg-white">
            {group}
          </div>
          {map(actions, renderAction)}
        </div>
      ));
    } else {
      return compact(actions).map(renderAction);
    }
  };

  return createPortal(
    <div
      className={`fixed inset-0 flex flex-col items-center justify-end p-4 select-none ${
        visible ? "pointer-events-auto" : "pointer-events-none"
      }`}
      style={{
        zIndex: 1100,
      }}
    >
      <div
        className={`absolute inset-0 bg-black/30 transition-opacity ${
          visible ? "opacity-100" : "opacity-0"
        }`}
        onClick={handleClose}
      />
      <div
        className={`flex flex-col max-h-full w-full max-w-md space-y-2 transform-gpu transition-all ${
          visible ? "translate-y-0 opacity-100" : "translate-y-full opacity-0"
        }`}
        style={{
          paddingBottom: appInset.bottom,
        }}
      >
        <div className="w-full flex flex-col bg-white overflow-hidden rounded-xl relative text-md">
          {title && (
            <div
              className={`text-center text-sm text-neutral-400 border-b p-1.5 select-none flex-shrink-0`}
            >
              {title}
            </div>
          )}
          <div className="overflow-auto flex-grow">{renderActions()}</div>
        </div>
        {onClose && (
          <button
            className="w-full bg-white rounded-xl relative text-center p-3 text-red hover:bg-neutral-50 transition-colors"
            onClick={handleClose}
          >
            {t("shared.cancel")}
          </button>
        )}
      </div>
    </div>,
    document.body,
  );
}
