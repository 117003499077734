import React, { useContext, useMemo } from "react";
import { UserContext } from "~/contexts/user-context";
import { compact } from "lodash";
import FolderTag from "./FolderTag";
import InlineTooltip from "~/components/shared/InlineTooltip";

export default function ContactFolders(props) {
  const { folderIds } = props;

  const { organization } = useContext(UserContext);

  const folders = useMemo(
    () =>
      compact(
        folderIds?.map((folderId) => {
          return organization.folders.find((folder) => folder.id === folderId);
        }),
      ),
    [folderIds, organization.folders],
  );

  const extraFoldersText = folders
    .slice(2)
    .map((folder) => folder.name)
    .join(", ");

  if (!folders || folders?.length == 0) return null;

  return (
    <div className="flex gap-2">
      {folders.slice(0, 2).map((folder) => (
        <FolderTag key={folder.id} folder={folder} size="md" />
      ))}
      {folders.length > 2 ? (
        <InlineTooltip text={extraFoldersText} align="right">
          <div
            className={`text-xs font-normal text-dark-gray bg-light-gray rounded-2md px-1.5 py-1 whitespace-nowrap flex-shrink-0 cursor-pointer`}
          >
            +{folders.length - 2}
          </div>
        </InlineTooltip>
      ) : null}
    </div>
  );
}
