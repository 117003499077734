import React, { useContext, useState } from "react";

import shopifyIcon from "../../../assets/images/shopify_icon.png";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { UserContext } from "~/contexts/user-context";
import ShopifyCustomerDetails from "../ecommerce/ShopifyCustomerDetails";
import { UIContext } from "~/contexts/ui-context";

export default function ConversationStatusHeader(props) {
  const { conversation } = props;

  const { organization } = useContext(UserContext);
  const { showMenu } = useContext(UIContext);

  const clientsFolder = organization?.folders.find((f) => f.name === "Clients");

  const [shopifyDetails, showShopifyDetails] = useState(false);

  const linkShopifyCustomer = () => {
    showMenu({
      title: "Shopify customer",
      actions: [
        {
          label: "Create new customer",
        },
        {
          label: "Link existing customer",
        },
      ],
    });
  };

  if (clientsFolder && conversation.folder_ids?.includes(clientsFolder?.id)) {
    return (
      <div
        className="px-4 py-2 flex items-center space-x-2 border-b flex-shrink-0 cursor-pointer"
        onClick={() => showShopifyDetails(true)}
      >
        <div className="w-7 h-7 border rounded-lg flex items-center justify-center">
          <img src={shopifyIcon} className="w-5 h-auto" />
        </div>
        <div className="space-y-1 flex-grow">
          <div className="text-2sm font-medium">Shopify customer</div>
          <div className="text-2xs text-dark-gray">
            {conversation?.contact?.email}
          </div>
        </div>
        <div className="bg-yellow-500 text-dark-yellow-500 font-medium rounded-md px-2 py-1 text-2xs">
          Order #2357
        </div>
        <ChevronRightIcon className="w-5 text-darker-gray" />
        {shopifyDetails && (
          <ShopifyCustomerDetails
            contact={conversation.contact}
            onClose={() => showShopifyDetails(false)}
          />
        )}
      </div>
    );
  } else {
    return (
      <div
        className="px-4 py-2 flex items-center space-x-2 border-b flex-shrink-0 cursor-pointer"
        onClick={linkShopifyCustomer}
      >
        <div className="w-7 h-7 border rounded-lg flex items-center justify-center opacity-60">
          <img src={shopifyIcon} className="w-5 h-auto" />
        </div>
        <div className="space-y-1 flex-grow">
          <div className="text-2sm font-medium">Link Shopify customer...</div>
        </div>
        <ChevronRightIcon className="w-5 text-darker-gray" />
      </div>
    );
  }
}
