import React from "react";
import {
  PlayCircleIcon,
  ArrowRightCircleIcon,
  PauseCircleIcon,
} from "@heroicons/react/20/solid";

export default function scenarioStatusIcon(status, small = false) {
  const size = small ? "w-5" : "w-7";

  switch (status) {
    case "live":
      return (
        <PlayCircleIcon className={`${size} text-automation flex-shrink-0`} />
      );
    case "manual":
      return (
        <ArrowRightCircleIcon className={`${size} text-manual flex-shrink-0`} />
      );
    default:
      return (
        <PauseCircleIcon className={`${size} text-medium-gray flex-shrink-0`} />
      );
  }
}
