import React, { useContext } from "react";

import StoryMessage from "./StoryMessage";
import { UserContext } from "~/contexts/user-context";

export default function StoryMention(props) {
  const { contact, url, received, id } = props;

  const { organization } = useContext(UserContext);

  const caption = received
    ? `${contact.username} mentioned you in their story`
    : `You mentioned ${contact.username} in your story`;

  const storyUrl = `https://www.instagram.com/stories/${
    received ? organization.username : contact.username
  }/${id}/`;

  return (
    <StoryMessage
      storyUrl={storyUrl}
      caption={caption}
      mediaUrl={url}
      received={received}
    />
  );
}
