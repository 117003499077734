import "react-calendar/dist/Calendar.css";
import "~/stylesheets/application.scss";

import i18n from "../locales/i18n";

import moment from "moment";
moment.locale(i18n.language);

import { Settings } from "luxon";
Settings.defaultLocale = i18n.language;

// React
import React from "react";
import ReactDOM from "react-dom";

import App from "~/components/App";
import NotFound from "~/components/NotFound";
import PublicAppointmentPage from "~/components/appointments/PublicAppointmentPage";
import PublicCheckoutPage from "~/components/checkout/PublicCheckoutPage";
import PublicPaymentPage from "~/components/payments/PublicPaymentPage";
import RequestInvoicePage from "~/components/payments/RequestInvoicePage";

import SignupPage from "../components/signup/SignupPage";
import LoginPage from "../components/login/LoginPage";

const components = {
  App,
  NotFound,
  PublicAppointmentPage,
  PublicCheckoutPage,
  PublicPaymentPage,
  RequestInvoicePage,
  SignupPage,
  LoginPage,
};

document.addEventListener("DOMContentLoaded", () => {
  const node = document.getElementById("app");
  if (!node) return;

  const name = node.getAttribute("data-component");
  if (!name) return;

  const Component = components[name];

  const props = JSON.parse(node.getAttribute("data-props"));

  if (Component) {
    ReactDOM.render(<Component {...props} />, node);
  }
});
