import React from "react";
import classNames from "~/utils/classNames";
import Button from "./Button";

export default function ButtonGroup(props) {
  const { buttons } = props;

  return (
    <div className={classNames("inline-flex")}>
      {buttons.map((button, index) => (
        <Button
          key={index}
          className={classNames(
            "flex-grow",
            "focus:relative",
            index < buttons.length - 1 && "!border-r-none -mr-px",
            index == 0
              ? "!rounded-r-none"
              : index == buttons.length - 1
                ? "!rounded-l-none"
                : "!rounded-none",
          )}
          {...button}
        />
      ))}
    </div>
  );
}
