import React from "react";
import classNames from "~/utils/classNames";
import { isNumber } from "lodash";

const Tab = ({ tab, className = "" }) => {
  const { icon: Icon, active, title, count, onClick } = tab;
  return (
    <button
      onClick={onClick}
      className={classNames(
        active
          ? "border-primary text-primary"
          : "text-gray-500 hover:border-gray-300 hover:text-gray-700",
        "whitespace-nowrap border-b-2 px-4 py-3 text-sm font-medium -mb-0.5",
        className,
      )}
      aria-current={active ? "page" : undefined}
    >
      {Icon && <Icon className="w-4 mr-1 inline-block" />}
      {title}
      {isNumber(count) && (
        <span
          className={classNames(
            "ml-2 inline-block font-medium px-1.5 py-0.75 rounded-lg text-xs",
            active
              ? "bg-light-primary text-primary"
              : "bg-gray-100 text-gray-500",
          )}
        >
          {count}
        </span>
      )}
    </button>
  );
};

export default function Tabs(props) {
  const { tabs, className = "", tabClassName = "" } = props;

  return (
    <nav className={classNames("flex border-b-2", className)}>
      {tabs.map((tab) => (
        <Tab key={tab.id || tab.title} tab={tab} className={tabClassName} />
      ))}
    </nav>
  );
}
