import React, { useState } from "react";
import classNames from "~/utils/classNames";

const InputOption = (props) => {
  const { option } = props;

  if (typeof option === "string") {
    return <option value={option}>{option}</option>;
  } else if (typeof option === "object") {
    return (
      <option value={option.value} disabled={option.disabled}>
        {option.label}
      </option>
    );
  }

  return null;
};

export default function InputSelect(props) {
  const {
    label,
    placeholder,
    value,
    onChange = () => {},
    options = [],
  } = props;

  return (
    <div className="space-y-1">
      {label ? (
        <div className="text-base font-medium leading-5 text-headings">
          {label}
        </div>
      ) : null}
      <select
        className={classNames(
          "w-full px-3 py-2.5 pr-8 outline-none border-none ring-1 ring-border rounded-lg shadow-sm text-sm leading-5 placeholder:text-muted focus:ring-2",
          value ? "text-body" : "text-placeholder",
        )}
        value={value}
        onChange={(evt) => onChange(evt.target.value)}
      >
        {placeholder && (
          <option value="" selected disabled>
            {placeholder}
          </option>
        )}
        {options.map((option, index) => (
          <InputOption key={index} option={option} />
        ))}
      </select>
    </div>
  );
}
