import React, { useState, useContext, useEffect } from "react";
import { UserContext } from "~/contexts/user-context";
import ActiveScenarios from "./ActiveScenarios";
import RecentCampaigns from "./RecentCampaigns";
import { DateTime } from "luxon";
import { useSearchParams } from "react-router-dom";
import {
  PresentationChartLineIcon,
  InboxIcon,
  PaperAirplaneIcon,
  FireIcon,
  ArrowTrendingDownIcon,
  ArrowTrendingUpIcon,
} from "@heroicons/react/20/solid";
import Datepicker from "react-tailwindcss-datepicker";
import i18n from "~/locales/i18n";
import { useTranslation } from "react-i18next";
import DashboardMetricCard from "./DashboardMetricCard";

export default function DashboardPage() {
  const locale = i18n.language.split("-")[0];
  const { t } = useTranslation();

  const { organization, loadDashboardData } = useContext(UserContext);
  const [searchParams] = useSearchParams();
  const [data, setData] = useState({});
  const [dateRange, setDateRange] = useState({
    startDate: searchParams.has("start_date")
      ? searchParams.get("start_date")
      : DateTime.now().minus({ days: 7 }).toISODate(),
    endDate: searchParams.has("end_date")
      ? searchParams.get("end_date")
      : DateTime.now().toISODate(),
  });

  const [loadingData, setLoadingData] = useState(true);

  useEffect(async () => {
    const params = {
      start_date: dateRange.startDate,
      end_date: dateRange.endDate,
    };
    setLoadingData(true);
    const data = await loadDashboardData(params);
    setData(data);
    setLoadingData(false);
  }, [organization.id, dateRange]);

  const {
    engaged_contacts,
    handled_engaged_contacts_rate,
    engaged_contacts_evolution,
    activated_contacts,
    activated_contacts_evolution,
    conversions,
    conversions_evolution,
  } = data;

  const metricCards = [
    {
      icon: <InboxIcon className="w-5" />,
      title: t("dashboard.engaged_contacts"),
      value: engaged_contacts,
      evolution: engaged_contacts_evolution,
      helpMessage: t("dashboard.tooltips.engaged_contacts"),
      subtext: t("dashboard.replied_share", {
        rate: handled_engaged_contacts_rate,
      }),
    },
    {
      icon: <PaperAirplaneIcon className="w-5" />,
      title: t("dashboard.activated_contacts"),
      value: activated_contacts,
      evolution: activated_contacts_evolution,
      helpMessage: t("dashboard.tooltips.activated_contacts"),
    },
    {
      icon: <FireIcon className="w-5" />,
      title: t("dashboard.conversions"),
      value: conversions,
      evolution: conversions_evolution,
      helpMessage: t("dashboard.tooltips.conversions"),
    },
  ];

  return (
    <div className="p-3 md:p-6 w-full flex flex-col h-screen overflow-y-auto">
      <div className="flex items-center pt-8 pb-4 gap-x-2 mb-4">
        <PresentationChartLineIcon className="w-7" />
        <h1 className="font-bold text-4xl text-headings">
          {t("dashboard.title")}
        </h1>
      </div>
      <div className="flex flex-col gap-y-4">
        <div className="flex justify-between w-full items-center">
          <h2 className="font-bold text-headings text-xl">
            {t("dashboard.metrics")}
          </h2>
          <div className="relative z-30">
            <Datepicker
              i18n={locale}
              primaryColor="indigo"
              separator={t("shared.datepicker.separator")}
              displayFormat={"D MMMM YYYY"}
              showShortcuts={true}
              showFooter={true}
              value={dateRange}
              onChange={setDateRange}
              maxDate={new Date()}
            />
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
          {metricCards.map((metric) => (
            <DashboardMetricCard {...metric} loading={loadingData} />
          ))}
        </div>
        <div className="flex-col flex lg:flex-row gap-4">
          <div className="flex-1">
            <ActiveScenarios />
          </div>
          <div className="flex-1">
            <RecentCampaigns />
          </div>
        </div>
      </div>
    </div>
  );
}
