import React, { useContext } from "react";
import RelativeDate from "~/components/utils/RelativeDate";
import { useTranslation } from "react-i18next";
import { InboxContext } from "~/contexts/inbox-context";
import RepliedIcon from "../inbox/RepliedIcon";
import CommentBubble from "./CommentBubble";
import classNames from "~/utils/classNames";

const CommentButton = (props) => {
  return (
    <button
      className="text-xs p-1 text-gray-500 font-medium transition-colors hover:text-gray-900"
      {...props}
    >
      {props.label}
    </button>
  );
};

export default function CommentItem(props) {
  const { t } = useTranslation();

  const { comment, setComment, children } = props;

  const {
    id,
    contact,
    received_at,
    text,
    parent_id,
    replied,
    hidden,
    from_self,
    private_repliable,
  } = comment;

  const { hideComment, deleteComment, commentReplyDialog, privateReplyDialog } =
    useContext(InboxContext);

  const handleHide = async () => {
    setComment({ ...comment, hidden: true });
    const res = await hideComment(id, true);
    setComment(res);
  };

  const handleUnhide = async () => {
    setComment({ ...comment, hidden: false });
    const res = await hideComment(id, false);
    setComment(res);
  };

  const handleReply = () => {
    commentReplyDialog(id, (res) => setComment(res));
  };

  const handleDelete = () => {
    deleteComment(id, () => setComment(null));
  };

  return (
    <div
      className={classNames(
        !parent_id && "border-b border-neutral-100",
        hidden && "opacity-50",
      )}
    >
      <div
        className={classNames(
          "w-full  flex-shrink-0 overflow-hidden flex space-x-2 flex-grow items-start justify-between",
          parent_id ? "pl-11 pr-3 pt-1 pb-1" : "px-3 pt-3 pb-2",
        )}
      >
        <a
          href={contact.instagram_url}
          target="_blank"
          className="flex-shrink-0"
        >
          <img
            className={classNames(
              parent_id ? "w-5 h-5" : "w-6 h-6",
              "rounded-full",
            )}
            src={contact?.profile_picture}
          />
        </a>
        {/* Infos */}
        <div className="flex-grow flex flex-col items-start overflow-hidden">
          {contact.username?.length > 0 && (
            <a
              href={contact.instagram_url}
              target="_blank"
              className={`block truncate text-xs font-medium leading-none mb-2`}
              dangerouslySetInnerHTML={{ __html: contact.username }}
            />
          )}
          <div className="flex items-center space-x-2 max-w-full">
            <CommentBubble text={text} />
            {/* TODO show like count on comments */}
            {/* <div className="flex items-center">
              <HeartIcon className="w-3 h-3 text-neutral-400" />
              <span className="text-2xs text-gray-400 ml-0.5 mb-0.5">
                {comment.like_count || 0}
              </span>
            </div> */}
          </div>
          <div className="flex space-x-1 items-center">
            {!parent_id && !hidden && (
              <CommentButton label={t("shared.reply")} onClick={handleReply} />
            )}
            {from_self ? (
              <CommentButton
                onClick={handleDelete}
                label={t("shared.delete")}
              />
            ) : (
              <CommentButton
                onClick={hidden ? handleUnhide : handleHide}
                label={hidden ? t("shared.unhide") : t("shared.hide")}
              />
            )}
          </div>
        </div>
        {/* Date & status */}
        <div className="flex flex-col flex-shrink-0 justify-between items-end">
          <div className="text-xs font-light text-neutral-300 whitespace-nowrap ml-2 mb-1">
            <RelativeDate date={received_at} />
          </div>
          {replied ? (
            <RepliedIcon className="w-3 h-3 text-neutral-300 mt-1" />
          ) : null}
        </div>
      </div>
      {children}
    </div>
  );
}
