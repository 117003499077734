import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import FolderPickerField from "../../../inbox/folders/FolderPickerField";
import { FlowContext } from "~/contexts/flow-context";

const eventsList = ["CRM - New Sign Up", "CRM - Account Deletion"];

export default function CustomEventOptions(props) {
  const { t } = useTranslation();

  const { trigger } = props;

  const { editTriggerOptions } = useContext(FlowContext);

  const handlePick = (eventId) => {
    editTriggerOptions(trigger.id, { custom_event_id: eventId });
  };

  return (
    <>
      <div className="flex items-center space-x-2">
        <div className="text-md whitespace-nowrap">
          {t(`automation.scenarios.trigger_type.custom_event.select_event`)}
        </div>
        <select
          className="input-select"
          onChange={(e) => handlePick(e.target.value)}
        >
          {eventsList.map((event) => (
            <option key={event} value={event}>
              {event}
            </option>
          ))}
        </select>
      </div>
    </>
  );
}
