import { ChevronRightIcon } from "@heroicons/react/20/solid";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function SurveyItem(props) {
  const { t } = useTranslation();

  const { survey } = props;

  const { id, title, sent_count, reply_count } = survey;

  const metrics = {
    sent: sent_count,
    replied: reply_count,
  };

  return (
    <Link
      className="flex items-center justify-between p-3 sm:p-4 border-b sm:border sm:bg-lighter-gray hover:bg-light-gray transition-colors sm:rounded-2xl"
      to={`/automation/surveys/${id}`}
    >
      <div className="font-medium">{title}</div>
      <div className="flex-shrink-O flex items-center space-x-4">
        <div className="hidden sm:flex items-center space-x-4 sm:space-x-6">
          {["sent", "replied"].map((metric) => (
            <div
              key={metric}
              className="flex flex-col items-center space-y-0.5"
            >
              <div className="text-2sm text-darker-gray">
                {t(`surveys.metrics.${metric}`)}
              </div>
              <div className="text-md font-medium">{metrics[metric]}</div>
            </div>
          ))}
        </div>
        <ChevronRightIcon className="w-6 text-darker-gray" />
      </div>
    </Link>
  );
}
