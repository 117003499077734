import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

export default function Tooltip(props) {
  const {
    text,
    onClick,
    position = "top",
    align = "left",
    offset = 0,
    parentRef,
    delay = 2000,
  } = props;

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => setVisible(true), delay);
  }, []);

  const tooltipRef = useRef();

  const positionToolTip = () => {
    if (!tooltipRef.current) return;

    const parent = parentRef.current;
    const tooltip = tooltipRef.current;

    if (position === "top") {
      tooltip.style.top = parent.offsetTop - 40 + "px";
    } else {
      tooltip.style.top = parent.offsetTop + parent.offsetHeight + "px";
    }

    if (align === "left") {
      tooltip.style.left = parentRef.current.offsetLeft + offset + "px";
      tooltip.style.right = "auto";
    } else {
      tooltip.style.left = "auto";
      tooltip.style.right =
        window.innerWidth -
        parentRef.current.offsetLeft -
        parentRef.current.offsetWidth +
        offset +
        "px";
    }
  };

  useEffect(() => {
    positionToolTip();

    window.addEventListener("resize", positionToolTip);

    return () => window.removeEventListener("resize", positionToolTip);
  }, [parentRef?.current, tooltipRef?.current]);

  // Hide tooltip when clicking on parent element
  useEffect(() => {
    const hide = () => setVisible(false);

    if (parentRef) parentRef?.current?.addEventListener("click", hide);

    return () => {
      if (parentRef) parentRef?.current?.removeEventListener("click", hide);
    };
  }, [parentRef]);

  const handleClick = (evt) => {
    evt.stopPropagation();
    setVisible(false);
    setTimeout(onClick, 150);
  };

  if (!text || !parentRef?.current) return null;

  return createPortal(
    <div
      className={`transition-opacity absolute z-30 ${
        visible ? "opacity-100" : "opacity-0 pointer-events-none"
      } ${position === "top" ? "pb-2" : "pt-2"}`}
      ref={tooltipRef}
      // style={{
      //   top:
      //     position === "top"
      //       ? parentRef.current.offsetTop - 40
      //       : parentRef.current.offsetTop + parentRef.current.offsetHeight,
      //   left: align === "left" ? parentRef.current.offsetLeft + offset : "auto",
      //   right:
      //     align === "left"
      //       ? "auto"
      //       : window.innerWidth -
      //         parentRef.current.offsetLeft -
      //         parentRef.current.offsetWidth +
      //         offset,
      // }}
      onClick={handleClick}
    >
      <div className="bg-primary text-black px-4 py-2 rounded-lg text-2sm font-medium whitespace-nowrap">
        {text}
      </div>
      <div
        className={`w-0 h-0 border-y-8 border-x-8 border-transparent ${
          position === "top"
            ? "border-t-primary -bottom-2"
            : "border-b-primary -top-2"
        } absolute ${align === "left" ? "left-4" : "right-4"}`}
      />
    </div>,
    document.body
  );
}
