import React, { useEffect, useState } from "react";
import InputSelect from "../../elements/InputSelect";
import { useTranslation } from "react-i18next";
import FolderPickerField from "../../inbox/folders/FolderPickerField";
import Button from "../../elements/Button";

const folderOptions = ["in_a_folder", "not_in_a_folder"];

export default function FolderFilter(props) {
  const { t } = useTranslation();

  const { filter, onChange, onRemove, disabled } = props;

  const [folderOption, setFolderOption] = useState(
    filter.value ? "in_a_folder" : "not_in_a_folder",
  );

  const handlePickFolder = (folderId) => {
    onChange({ ...filter, value: folderId });
  };

  useEffect(() => {
    if (folderOption === "not_in_a_folder") {
      onChange({ ...filter, value: null });
    }
  }, [folderOption]);

  useEffect(() => {
    if (!filter.value) {
      setFolderOption("not_in_a_folder");
    } else {
      setFolderOption("in_a_folder");
    }
  }, [filter.value]);

  return (
    <div className="flex items-center space-x-2">
      <InputSelect
        options={folderOptions.map((option) => ({
          value: option,
          label: t(`contacts.filters.categories.folder.${option}`),
        }))}
        value={folderOption}
        onChange={setFolderOption}
      />
      {folderOption === "in_a_folder" && (
        <FolderPickerField
          value={filter.value}
          onPick={handlePickFolder}
          createNew={false}
        />
      )}
      <Button
        label={t("contacts.filters.remove_filter")}
        disabled={disabled}
        onClick={onRemove}
      />
    </div>
  );
}
