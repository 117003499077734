import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { FlowContext } from "~/contexts/flow-context";
import AutoDelay from "./AutoDelay";
import CustomNode from "./CustomNode";
import PaymentModal from "../../../payments/PaymentModal";
import { UserContext } from "~/contexts/user-context";
import { CubeIcon } from "@heroicons/react/20/solid";
import PaymentLinkFullCard from "../../../messages/PaymentLinkFullCard";

export default function PaymentLinkNode(props) {
  const { t } = useTranslation();

  const { editAction } = useContext(FlowContext);
  const { organization } = useContext(UserContext);

  const { data, selected } = props;

  const { id, editable = false, options } = data;
  const amount = options?.payment_amount || 0;

  const handlePickAmount = (amount) => {
    editAction(id, { options: { payment_amount: amount }, editable: false });
  };

  return (
    <CustomNode selected={selected}>
      <div
        className={`relative rounded-3xl ring-1 ring-indigo-400 ring-offset-1 ring-opacity-0 hover:ring-opacity-100 transition-all ${selected || editable ? "z-50 ring-opacity-100" : ""}`}
      >
        {amount > 0 ? (
          <PaymentLinkFullCard
            organization={organization}
            total={amount}
            currency={organization.currency}
          />
        ) : (
          <div
            className="py-3 px-4 bg-white border-2 border-medium-gray text-dark-gray border-dashed rounded-2xl cursor-pointer nodrag opacity-75 hover:opacity-100 transition-opacity flex space-x-2 items-center"
            onClick={() => editAction(id, { editable: true })}
          >
            <CubeIcon className="w-4" />
            <div className="text-md font-medium text-center">
              {t("automation.scenarios.actions.payment_link.placeholder")}
            </div>
          </div>
        )}
      </div>
      {editable && (
        <PaymentModal
          onClose={() => editAction(id, { editable: false })}
          onConfirm={handlePickAmount}
          confirmLabel={t("shared.confirm")}
        />
      )}
    </CustomNode>
  );
}
