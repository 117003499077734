import React from "react";

export default function RadioToggle(props) {
  const { options, value, onChange, className = "", disabled = false } = props;

  const handleChange = (value) => {
    if (!disabled) onChange(value);
  };

  return (
    <div
      className={
        "flex border border-primary rounded-lg overflow-hidden " + className
      }
    >
      {options.map((option, index) => (
        <button
          key={option.value}
          className={`p-2 flex-grow text-center text-md font-medium border-primary ${
            index < options.length - 1 && "border-r"
          } ${value == option.value ? "bg-primary" : ""}`}
          onClick={() => handleChange(option.value)}
          disabled={disabled}
        >
          {option.label}
        </button>
      ))}
    </div>
  );
}
