import { sum, truncate } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";

export default function SurveyResults(props) {
  const { t } = useTranslation();

  const { options = [] } = props;

  const totalReplies = sum(options.map((option) => option.replies));

  return (
    <div className="flex flex-col items-start space-y-2 overflow-y-auto max-h-40">
      {options.map((option, index) => (
        <div key={index} className="flex items-center space-x-2">
          <div className="text-sm font-medium whitespace-nowrap">
            {truncate(option.title, { length: 20 })}
          </div>
          <div
            className="rounded-md bg-light-gray px-2 py-1 leading-tight text-xs font-medium text-neutral-900 whitespace-nowrap"
            style={{
              width: `${100 * (option.replies / totalReplies)}%`,
            }}
          >
            {t("surveys.replies_count", { count: option.replies || 0 })}
          </div>
        </div>
      ))}
    </div>
  );
}
