import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";

import { appInset } from "~/utils/environment";
import Button from "~/components/elements/Button";

export default function VideoDrawer(props) {
  const { title, text, url, button, link, buttonLabel, onClose } = props;

  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);

  // animate appear
  useEffect(() => {
    setTimeout(() => setVisible(true), 50);
  }, []);

  const handleClose = () => {
    if (!visible) return;
    setVisible(false);
    setTimeout(onClose, 300);
  };

  return createPortal(
    <div
      className="fixed inset-0 flex flex-col justify-end items-center"
      style={{
        zIndex: 1100,
        paddingTop: appInset.top + 20,
      }}
    >
      <div
        className={`absolute inset-0 bg-black/30 transition-opacity duration-300 ${
          visible ? "opacity-100" : "opacity-0"
        }`}
        onClick={handleClose}
      />
      <div
        className={`max-w-md w-full bg-white rounded-t-2xl flex flex-col pt-4 overflow-y-auto transform-gpu transition-transform duration-300 ${
          visible ? "translate-y-0" : "translate-y-full"
        }`}
        style={{
          paddingBottom: appInset.bottom,
        }}
      >
        <div className="flex justify-between items-center px-6 mb-4">
          <div className="font-medium">{title}</div>
          <div onClick={handleClose} className="text-red text-md font-medium">
            {t("shared.close")}
          </div>
        </div>
        <div className="w-full flex flex-col overflow-hidden justify-center items-center px-4 pb-4">
          {text && <div className="text-md self-start mb-6">{text}</div>}
          <div className="aspect-vertical flex-shrink flex-grow overflow-hidden rounded-2xl shadow-lg">
            <video
              autoPlay
              loop
              muted
              playsInline
              className="object-contain bg-white w-full h-full rounded-2xl border"
            >
              <source src={url} type="video/mp4" />
            </video>
          </div>
          {button || link ? (
            <div className="flex flex-col space-y-2 w-full">
              {button}
              {link}
            </div>
          ) : null}
          {buttonLabel ? (
            <Button
              className="w-full"
              label={buttonLabel}
              onClick={handleClose}
            />
          ) : null}
        </div>
      </div>
    </div>,
    document.body,
  );
}
