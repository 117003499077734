import React from "react";
import { useTranslation } from "react-i18next";
import CustomNode from "./CustomNode";
import LoveReaction from "../../../messages/LoveReaction";
import AutoDelay from "./AutoDelay";

export default function LikeNode(props) {
  const { t } = useTranslation();

  const { selected } = props;

  return (
    <CustomNode selected={selected}>
      <div className="relative">
        <div
          className={`relative py-2.5 px-3 bg-white border rounded-3xl nodrag ring-1 ring-offset-1 ring-indigo-400 hover:ring-opacity-100 transition-all flex space-x-2 items-center ${
            selected ? "ring-opacity-100" : "ring-opacity-0"
          }`}
        >
          <LoveReaction className="absolute left-2 -bottom-3.5" />
          <div className="text-md">
            {t("automation.scenarios.actions.like.title")}
          </div>
        </div>
      </div>
    </CustomNode>
  );
}
