import {
  BoltIcon,
  CreditCardIcon,
  UserIcon,
  UsersIcon,
} from "@heroicons/react/20/solid";
import React from "react";
import { useTranslation } from "react-i18next";

export default function FreePlan() {
  const { t } = useTranslation();

  return (
    <div className="p-6 space-y-6 border rounded-2xl text-headings">
      <div className="space-y-2">
        <UserIcon className="w-8 h-8" />
        <div className="text-lg leading-6 font-medium">
          {t("onboarding.free_trial.free_plan.title")}
        </div>
        <div className="leading-5">
          {t("onboarding.free_trial.free_plan.description")}
        </div>
      </div>
      <div className="font-black text-4xl leading-none">
        {t("onboarding.free_trial.free_plan.price")}
      </div>
      <div className="h-px" />
      <div className="text-sm text-muted">
        {t("onboarding.free_trial.free_plan.cta")}
      </div>
      <div className="space-y-4">
        <div className="flex space-x-2">
          <CreditCardIcon className="w-5 flex-shrink-0" />
          <div className="font-semibold text-sm leading-5">
            {t("onboarding.free_trial.free_plan.credit_card")}
          </div>
        </div>
        <div className="flex space-x-2">
          <UsersIcon className="w-5 flex-shrink-0" />
          <div className="font-semibold text-sm leading-5">
            {t("onboarding.free_trial.free_plan.contacts_limit")}
          </div>
        </div>
        <div className="flex space-x-2">
          <BoltIcon className="w-5 flex-shrink-0" />
          <div className="font-semibold text-sm leading-5">
            {t("onboarding.free_trial.free_plan.automation_limit")}
          </div>
        </div>
      </div>
    </div>
  );
}
