import React, { useMemo, useState } from "react";
import SkeletonLoader from "~/components/utils/SkeletonLoader";

export default function ContactPicture(props) {
  const {
    contact,
    large = false,
    linkToProfile = false,
    loading = false,
    className = "",
  } = props;

  const [showPicture, setShowPicture] = useState(true);

  if (loading) {
    return (
      <SkeletonLoader
        round
        width={large ? 44 : 32}
        height={large ? 44 : 32}
        className={className}
      />
    );
  }

  const renderPicture = () => {
    return showPicture ? (
      <img
        className={`${
          large ? "w-11 h-11" : "w-8 h-8"
        } rounded-full flex-shrink-0 pointer-events-none ${className}`}
        onError={() => setShowPicture(false)}
        src={
          contact?.profile_picture ||
          `/avatar${((contact?.id || 0) % 4) + 1}.png`
        }
      />
    ) : (
      <img
        className={`${
          large ? "w-11 h-11" : "w-8 h-8"
        } rounded-full flex-shrink-0 pointer-events-none bg-light-gray ${className}`}
        src={`/avatar${(contact?.id % 4) + 1}.png`}
      />
    );
  };

  return linkToProfile ? (
    <a
      href={contact?.instagram_url}
      className="block flex-shrink-0"
      target="_blank"
    >
      {renderPicture()}
    </a>
  ) : (
    renderPicture()
  );
}
