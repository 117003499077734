import React, { useState, useEffect, useRef, useContext } from "react";

import { HOURS, CELL_HEIGHT } from "./constants";
import { useNavigate } from "react-router-dom";
import calendarPosition from "~/utils/calendarPosition";
import { DateTime } from "luxon";
import { UserContext } from "~/contexts/user-context";
import { CalendarContext } from "~/contexts/calendar-context";

export default function CalendarEvent(props) {
  const { active, event, onClick = null, slot = false } = props;

  const {
    status = "sent",
    start_time,
    duration,
    title,
    appointment_id,
  } = event;

  const { user } = useContext(UserContext);

  const navigate = useNavigate();

  const [visible, setVisible] = useState(false);
  const visibleTimeoutRef = useRef(null);

  // animate appear
  useEffect(() => {
    visibleTimeoutRef.current = setTimeout(() => setVisible(true), 50);
    return () => clearTimeout(visibleTimeoutRef.current);
  }, []);

  const handleClick = () => {
    if (onClick) {
      onClick();
      return;
    }
    if (!active) return;
    if (appointment_id)
      navigate(`/calendar/${appointment_id}?eventId=${event.id}`);
  };

  const startTime = DateTime.fromISO(start_time).setZone(user.time_zone);

  const startPosition = calendarPosition(startTime);
  const length = Math.min(duration / 60, 23 - startTime.hour);

  const isExpired = status == "sent" && startTime < DateTime.now();

  const statusClassName = (status) => {
    switch (status) {
      case "confirmed":
        return "bg-light-green text-dark-green p-1";
      case "selected":
        return "bg-white border-primary border text-primary p-1";
      case "cancelled":
        return `bg-light-red text-dark-red p-1 opacity-50`;
      default:
        return "bg-yellow-500 text-dark-yellow-500 p-1";
    }
  };

  // Hide past events that were not confirmed
  if (isExpired) return null;

  return (
    <div
      className={`absolute z-30 ${
        slot ? "left-1.5 right-0" : "left-0 right-0.5"
      } transition-all ease-out ${
        status != "selected" || visible
          ? "scale-100 opacity-100 "
          : "scale-90 opacity-0"
      } rounded ${statusClassName(status)} overflow-hidden text-ellipsis ${
        !active && status != "selected"
          ? "pointer-events-none"
          : "cursor-pointer"
      }`}
      style={{
        top: `${startPosition}px`,
        height: `${length * CELL_HEIGHT - 4}px`,
      }}
      onClick={handleClick}
    >
      <div className={`text-4xs font-medium break-all mb-1`}>{title}</div>
    </div>
  );
}
