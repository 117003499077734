import React from "react";
import BottomDrawer from "~/components/shared/BottomDrawer";
import ContactHeader from "../contacts/ContactHeader";
import ContactInsights from "../contacts/ContactInsights";
import formatAmount from "~/utils/formatAmount";
import ContactDetails from "../contacts/ContactDetails";
import ContactDetailsField from "../contacts/ContactDetailsField";
import {
  ChevronDownIcon,
  ChevronRightIcon,
  PlusIcon,
} from "@heroicons/react/20/solid";

export default function ShopifyCustomerDetails(props) {
  const { contact, onClose } = props;

  return (
    <BottomDrawer title="Shopify customer details" onClose={onClose}>
      <div className="w-full flex flex-col overflow-auto justify-start items-stretch pb-4">
        <div className="p-4">
          <ContactHeader
            contact={contact}
            right={
              <div className="text-right space-y-1">
                <div className="text-2xs text-dark-gray">Amount spent</div>
                <div className="text-md font-medium">
                  {formatAmount(421, "EUR")}
                </div>
              </div>
            }
          />
        </div>
        <ContactDetails contact={contact} />
        <ContactDetailsField
          label="Address"
          value={
            <>
              14 rue Saint-Lazare <br />
              75009 Paris <br />
              France
            </>
          }
        />
        <ContactDetailsField
          label="Tags"
          value={
            <div className="flex flex-wrap gap-1.5">
              <div className="bg-light-gray rounded-md px-2 py-1 text-2xs">
                Paris
              </div>
              <div className="bg-light-gray rounded-md px-2 py-1 text-2xs">
                VIP
              </div>
              <div className="bg-light-gray rounded-md px-2 py-1 text-2xs">
                Popup Jan 2023
              </div>
              <div className="bg-light-gray rounded-md px-2 py-1 text-2xs">
                <PlusIcon className="w-3" />
              </div>
            </div>
          }
        />
        <div className="py-6 px-4 space-y-4">
          <div className="text-md">Orders history</div>

          <div className="bg-lighter-gray rounded-xl">
            <div className="flex items-center p-3 border-b border-b-white">
              <div className="text-2sm font-bold mr-4">#2357</div>
              <div className="text-2sm flex-grow">August 2nd</div>
              <div className="bg-yellow-500 text-dark-yellow-500 font-medium rounded-md px-2 py-1 text-2xs mr-4">
                Unfulfilled
              </div>
              <div className="text-2sm font-medium mr-2">
                {formatAmount(248, "EUR")}
              </div>
              <ChevronDownIcon className="w-5" />
            </div>
            <div className="py-1">
              <div className="flex items-center px-3 py-2">
                <div className="w-5 h-5 rounded-md bg-medium-gray mr-2"></div>
                <div className="text-2xs font-medium flex-grow">
                  Black Sweater x 1
                </div>
                <div className="text-2xs">{formatAmount(180, "EUR")}</div>
              </div>
              <div className="flex items-center px-3 py-2">
                <div className="w-5 h-5 rounded-md bg-medium-gray mr-2"></div>
                <div className="text-2xs font-medium flex-grow">
                  Printed Tote Bag x 1
                </div>
                <div className="text-2xs">{formatAmount(60, "EUR")}</div>
              </div>
              <div className="flex items-center px-3 py-2">
                <div className="w-5 h-5 rounded-md mr-2"></div>
                <div className="text-2xs font-medium flex-grow">
                  Express Shipping
                </div>
                <div className="text-2xs">{formatAmount(8, "EUR")}</div>
              </div>
            </div>
          </div>

          <div className="bg-lighter-gray rounded-xl">
            <div className="flex items-center p-3">
              <div className="text-2sm font-bold mr-4">#1862</div>
              <div className="text-2sm flex-grow ">June 20th</div>
              <div className="bg-light-green text-dark-green font-medium rounded-md px-2 py-1 text-2xs mr-4">
                Delivered
              </div>
              <div className="text-2sm font-medium mr-2">
                {formatAmount(173, "EUR")}
              </div>
              <ChevronRightIcon className="w-5" />
            </div>
          </div>
        </div>
      </div>
    </BottomDrawer>
  );
}
