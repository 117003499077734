import React, { useContext, useEffect, useState } from "react";
import Fuse from "fuse.js";
import { keys, mapValues, reverse, sortBy, sum, groupBy } from "lodash";
import { Link, useNavigate, useParams } from "react-router-dom";

import { UserContext } from "~/contexts/user-context";

import helpImageEn from "../../../assets/images/payment_link_help_en.png";
import helpImageFr from "../../../assets/images/payment_link_help_fr.png";

import PageTitle from "~/components/shared/PageTitle";
import FullScreen from "~/components/utils/FullScreen";
import FilterButton from "../inbox/FilterButton";
import PaymentsListItem from "./PaymentsListItem";
import EmptyInbox from "../inbox/EmptyInbox";
import BottomMenu from "~/components/shared/BottomMenu";

import paymentLabel from "~/utils/paymentLabel";
import paymentClassName from "~/utils/paymentClassName";
import paymentStatusIcon from "~/utils/paymentStatusIcon";
import formatAmount from "~/utils/formatAmount";
import BalancePage from "./BalancePage";
import ScreenSlide from "~/components/utils/ScreenSlide";
import { useTranslation } from "react-i18next";
import PullToRefresh from "~/components/shared/PullToRefresh";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import SelectHeader from "~/components/shared/SelectHeader";
import PaymentsBatchActions from "./PaymentsBatchActions";
import PaymentDetails from "./PaymentDetails";
import Button from "~/components/elements/Button";
import i18n from "../../locales/i18n";
import { PaymentsContext } from "~/contexts/payments-context";
import { DateTime } from "luxon";
import Input from "~/components/elements/Input";
import Tabs from "~/components/elements/Tabs";
import Loader from "~/components/utils/Loader";

export default function PaymentsPage(props) {
  const { showBalance, showSettings } = props;

  const navigate = useNavigate();

  const { paymentId } = useParams();

  const { t } = useTranslation();
  const locale = i18n.language.split("-")[0];

  const { user } = useContext(UserContext);
  const { loadingPayments, payments, loadAllPayments } =
    useContext(PaymentsContext);

  // Search
  const [searchQuery, setSearchQuery] = useState("");
  const [searchedPayments, setSearchedPayments] = useState(payments);

  const handleSearchPayments = () => {
    if (searchQuery.length) {
      const fuse = new Fuse(payments, {
        keys: ["amount", "contact.name", "contact.email"],
        threshold: 0.3,
      });
      const results = fuse.search(searchQuery);
      setSearchedPayments(results.map((result) => result.item));
    } else {
      setSearchedPayments(payments);
    }
  };

  useEffect(handleSearchPayments, [searchQuery, payments]);

  // Filters
  const [activeFilter, setActiveFilter] = useState();
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const filterActions = ["awaiting", "paid", "manual", "cancelled"].map(
    (filter) => ({
      label: (
        <div
          className={`inline-flex space-x-1 items-center justify-center py-1.5 px-3 rounded-lg ${paymentClassName(
            filter,
          )}`}
        >
          <div>{paymentStatusIcon(filter)}</div>
          <div>{paymentLabel(filter)}</div>
        </div>
      ),
      action: () => setActiveFilter(filter),
    }),
  );
  if (activeFilter)
    filterActions.push({
      label: t("payments.show_all"),
      action: () => setActiveFilter(null),
    });

  const filteredPayments = activeFilter
    ? searchedPayments.filter((payment) => payment.status == activeFilter)
    : searchedPayments;

  // Tabs
  const [activeTab, setActiveTab] = useState("active");
  const tabs = [
    {
      id: "active",
      active: activeTab == "active",
      title: t("payments.tabs.active"),
      count: filteredPayments.filter((p) => !p.archived).length,
      onClick: () => setActiveTab("active"),
    },
    {
      id: "archived",
      active: activeTab == "archived",
      title: t("payments.tabs.archived"),
      count: filteredPayments.filter((p) => p.archived).length,
      onClick: () => setActiveTab("archived"),
    },
  ];

  const tabPayments = filteredPayments.filter(
    (p) =>
      (activeTab == "active" && !p.archived) ||
      (activeTab == "archived" && p.archived),
  );

  // Select & Actions
  const [selecting, setSelecting] = useState(false);
  const [selectedPaymentIds, setSelectedPaymentIds] = useState([]);
  const clearSelection = () => {
    setSelectedPaymentIds([]);
  };
  const cancelSelection = () => {
    clearSelection();
    setSelecting(false);
  };
  const setSelected = (id) => {
    if (selectedPaymentIds.includes(id)) {
      setSelectedPaymentIds(selectedPaymentIds.filter((c) => c !== id));
    } else {
      setSelectedPaymentIds([...selectedPaymentIds, id]);
    }
  };
  const selectAll = () => {
    if (selectedPaymentIds.length > 0) {
      clearSelection();
    } else {
      setSelectedPaymentIds(tabPayments.map((p) => p.id));
    }
  };
  const selectedPayments = payments.filter((conv) =>
    selectedPaymentIds.includes(conv.id),
  );
  // Cancel on folder/filter change
  useEffect(cancelSelection, [activeFilter]);

  // Groups
  const groupedPayments = groupBy(reverse(tabPayments), (payment) => {
    const date = DateTime.fromISO(payment.created_at);
    if (date.hasSame(DateTime.now(), "year"))
      return date.startOf("month").toISO();
    return date.startOf("year").toISO();
  });

  const groupTotals = mapValues(groupedPayments, (payments) =>
    sum(payments.map((p) => (p.paid ? parseFloat(p.amount) : 0))),
  );

  const groupLabel = (group) => {
    const date = DateTime.fromISO(group);
    const now = DateTime.now();
    if (date.hasSame(now, "month")) return t("time.this_month");
    if (date.hasSame(now, "year")) return date.toFormat("MMMM");
    return date.toFormat("yyyy");
  };

  const sortedGroups = reverse(sortBy(keys(groupedPayments)));

  // Payment details
  const detailedPayment = payments.find((p) => p.id == paymentId);

  // Load payments on page load
  useEffect(loadAllPayments, []);

  return (
    <div className="sm:h-screen sm:flex sm:flex-grow">
      <FullScreen className="h-full flex flex-col sm:flex-grow sm:max-w-xl sm:border-r sm:relative">
        <div className="flex p-4 justify-between items-center">
          <PageTitle text={t("payments.title")} />
          <div className="flex flex-shrink-0 items-center space-x-4">
            <Link
              to="/payments/balance"
              className={`rounded-lg px-2 py-1 ${
                user.balance > 0 ? "bg-primary" : "bg-light-gray"
              } font-medium text-2sm`}
            >
              {t("payments.balance.title")}
            </Link>
            <Link
              to="/profile/payment_settings"
              className="rounded-lg py-1 font-medium text-2sm"
            >
              {t("shared.settings")}
            </Link>
          </div>
        </div>
        <div className="bg-white flex items-center space-x-2 px-3 pb-4 sm:pt-4">
          <Input
            className="flex-grow"
            placeholder={t("shared.search")}
            icon={MagnifyingGlassIcon}
            value={searchQuery}
            onChange={setSearchQuery}
            clearButton
            debounce
          />
          <FilterButton
            activeFilter={activeFilter}
            activeFilterIcon={
              <div
                className={
                  "w-full h-full flex items-center justify-center " +
                  paymentClassName(activeFilter)
                }
              >
                <div>{paymentStatusIcon(activeFilter)}</div>
              </div>
            }
            onClick={() => setFilterMenuOpen(true)}
          />
        </div>
        <div>
          <Tabs tabs={tabs} className="w-full" tabClassName="flex-grow" />
        </div>
        {filteredPayments?.length ? (
          <SelectHeader
            selecting={selecting}
            setSelecting={setSelecting}
            selectAll={selectAll}
            selectedCount={selectedPaymentIds.length}
            onCancel={cancelSelection}
          />
        ) : null}
        <PullToRefresh
          wrapperClassName="flex-grow"
          contentClassName="pb-24 sm:pb-12"
          onRefresh={loadAllPayments}
        >
          {filteredPayments?.length
            ? sortedGroups.map(
                (group) =>
                  groupedPayments[group] && (
                    <div key={group}>
                      <div className="px-3 py-1.5 select-none bg-white sticky top-0">
                        <div>
                          <span className="font-medium text-xs text-darker-gray">
                            {groupLabel(group)}
                          </span>
                          {groupTotals[group] > 0 ? (
                            <span className="ml-5 text-2xs text-dark-gray">
                              {t("payments.paid", {
                                amount: formatAmount(groupTotals[group]),
                              })}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      {reverse(
                        sortBy(groupedPayments[group], (p) =>
                          DateTime.fromISO(p.created_at),
                        ),
                      )?.map((payment) => (
                        <PaymentsListItem
                          key={payment.id}
                          payment={payment}
                          selecting={selecting}
                          selected={selectedPaymentIds.includes(payment.id)}
                          onSelect={() => setSelected(payment.id)}
                        />
                      ))}
                    </div>
                  ),
              )
            : null}
          {loadingPayments && (
            <div className="p-8 flex items-center justify-center">
              <Loader />
            </div>
          )}
          {!tabPayments?.length && !loadingPayments && (
            <EmptyInbox
              emoji="💸"
              title={t("payments.no_payments")}
              caption={t("payments.no_payments_description")}
              button={
                <div className="max-w-xs">
                  <div className="px-6 py-6">
                    <img
                      src={locale == "fr" ? helpImageFr : helpImageEn}
                      className="rounded-md overflow-hidden"
                    />
                  </div>
                  <Button
                    className="w-full"
                    label={t("payments.actions.set_up")}
                    to="/payments/settings"
                  />
                </div>
              }
            />
          )}
          {payments.length > 0 && !filteredPayments?.length && (
            <EmptyInbox
              emoji="💸"
              title={t("payments.no_filtered_payments")}
              caption={t("payments.no_filtered_payments_description")}
            />
          )}
        </PullToRefresh>
        {/* Selection */}
        {selecting && selectedPaymentIds.length > 0 ? (
          <PaymentsBatchActions
            payments={selectedPayments}
            cancel={cancelSelection}
          />
        ) : null}
      </FullScreen>
      {/* Balance */}
      <ScreenSlide
        visible={showBalance}
        origin="/payments"
        desktopInline
        className="sm:border-r sm:max-w-md"
      >
        <BalancePage />
      </ScreenSlide>
      {/* Filter menu */}
      {filterMenuOpen && (
        <BottomMenu
          title={t("shared.filter_by")}
          actions={filterActions}
          onClose={() => setFilterMenuOpen(false)}
        />
      )}
      {/* Details */}
      {detailedPayment && (
        <PaymentDetails
          payment={detailedPayment}
          onClose={() => navigate("/payments")}
        />
      )}
    </div>
  );
}
