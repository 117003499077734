import { PlusCircleIcon } from "@heroicons/react/20/solid";
import React from "react";
import { BaseEdge, EdgeLabelRenderer, getSmoothStepPath } from "reactflow";

export default function CustomEdge(props) {
  const {
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    markerEnd,
  } = props;

  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <BaseEdge
        path={edgePath}
        markerEnd={markerEnd}
        className="bg-medium-gray hover:bg-dark-gray"
      />
      <EdgeLabelRenderer>
        <div
          style={{
            position: "absolute",
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            // everything inside EdgeLabelRenderer has no pointer events by default
            // if you have an interactive element, set pointer-events: all
            // pointerEvents: "all",
          }}
          className="bg-white"
        >
          <PlusCircleIcon className="w-4 text-medium-gray" />
        </div>
      </EdgeLabelRenderer>
    </>
  );
}
