import { MarkerType } from "reactflow";
import { actionNodeHeight, triggerNodeHeight } from "./nodes/nodeHeight";
import dagre from "dagre";

const NODE_WIDTH = 300;

export default function generateNodesAndEdges(hasTriggers, actions, triggers) {
  const graph = new dagre.graphlib.Graph();
  graph.setGraph({ rankdir: "TB", nodesep: 100, ranksep: 70 });
  graph.setDefaultEdgeLabel(() => ({}));

  // newAction node
  function addNewActionNode(newActionId, parentId = null) {
    if (parentId) graph.setEdge(parentId.toString(), newActionId);
    graph.setNode(newActionId, {
      width: NODE_WIDTH,
      height: actionNodeHeight("newAction"),
      data: { parent_id: parentId },
      type: "newAction",
    });
  }

  // recursion function
  function addItem(action, previousId = null) {
    if (!action) return;
    if (action && !action._destroy) {
      graph.setNode(action.id.toString(), {
        width: NODE_WIDTH,
        height: actionNodeHeight(action),
        type: action.action_type,
        data: { ...action },
      });

      if (previousId)
        graph.setEdge(previousId.toString(), action.id.toString());

      const children = actions.filter(
        (a) => a.parent_id?.toString() === action.id.toString(),
      );

      if (children?.length > 0) {
        children?.forEach((child) => addItem(child, action.id));
      } else {
        addNewActionNode(`newAction_${action.id}`, action.id);
      }
    }
  }

  // Trigger node
  if (hasTriggers) {
    graph.setNode("trigger", {
      width: NODE_WIDTH,
      height: triggerNodeHeight(triggers),
      type: "trigger",
    });
  }

  // First action node
  const firstAction = actions.find(
    (action) => !action.parent_id && !action._destroy,
  );
  if (firstAction) {
    addItem(firstAction, hasTriggers ? "trigger" : null);
  } else {
    addNewActionNode("newAction_trigger", hasTriggers ? "trigger" : null);
  }

  dagre.layout(graph);

  const nodes = graph.nodes().map((nodeId) => {
    const node = graph.node(nodeId);

    return {
      id: nodeId,
      type: node.type,
      data: node.data,
      position: {
        x: node.x - node.width / 2,
        y: node.y - node.height / 2,
      },
    };
  });

  const edges = graph.edges().map((edge) => {
    return {
      id: `e_${edge.v}_${edge.w}`,
      source: edge.v,
      target: edge.w,
      type: edge.w.includes("newAction") ? "default" : "custom",
      markerEnd: {
        type: MarkerType.Arrow,
      },
    };
  });

  return {
    nodes,
    edges,
  };
}
