import React from "react";
import { useNavigate } from "react-router-dom";

import { ChevronLeftIcon } from "@heroicons/react/20/solid";

import ContactHeader from "../contacts/ContactHeader";
import EditContactFolders from "./folders/EditContactFolders";
import SkeletonLoader from "~/components/utils/SkeletonLoader";

export default function ConversationHeader(props) {
  const { conversation, back = null, loading, className } = props;

  const navigate = useNavigate();
  const handleBack = () => {
    if (back) {
      back();
    } else {
      navigate(-1);
    }
  };

  return (
    <div
      className={`bg-white border-b flex-shrink-0 pt-3 pb-2 z-10 ${className}`}
    >
      <div
        className={`flex items-start justify-between overflow-hidden pl-2 sm:pl-4`}
      >
        <button
          onClick={handleBack}
          className="w-8 h-9 flex justify-items-center items-center sm:hidden"
        >
          <ChevronLeftIcon className="h-6 w-6" />
        </button>
        <div className="flex-grow flex justify-between items-start pr-3">
          <ContactHeader
            contact={conversation?.contact}
            conversation={conversation}
            loading={loading}
            linkToProfile
          />
          {props?.children}
        </div>
      </div>

      <EditContactFolders
        contact={conversation?.contact}
        loading={loading}
        className="pl-10 pr-4"
      />
    </div>
  );
}
