import React from "react";
import Badge from "~/components/elements/Badge";
import { ChartBarIcon } from "@heroicons/react/20/solid";

export default function ContactFollowerGrowth(props) {
  const { value } = props;

  if (value == null) return null;

  return (
    <Badge
      color={value > 0 ? "green" : value == 0 ? "yellow" : "red"}
      icon={ChartBarIcon}
      label={Math.round(value * 100) + "%"}
    />
  );
}
