import React from "react";
import { useTranslation } from "react-i18next";

export default function ActionTypeMenuItem(props) {
  const { t } = useTranslation();

  const { action, disabled, onClick } = props;

  const { action_type, icon: Icon = null } = action;

  return (
    <button
      key={action_type}
      className="w-full sm:w-60 border-2 border-black border-opacity-20 hover:border-opacity-40 rounded-lg flex items-center space-x-3 px-3 py-2.5 transition-all disabled:opacity-30 disabled:pointer-events-none"
      onClick={onClick}
      disabled={disabled}
    >
      <Icon className="w-4 flex-shrink-0" />
      <div className="font-medium">
        {t(`automation.scenarios.actions.${action_type}.title`)}
      </div>
    </button>
  );
}
