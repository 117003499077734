import React, { useContext, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { ContentContext } from "~/contexts/content-context";
import { truncate } from "lodash";
import { UserContext } from "~/contexts/user-context";
import { useTranslation } from "react-i18next";
import { ArrowPathIcon } from "@heroicons/react/20/solid";
import Loader from "~/components/utils/Loader";
import Media from "../messages/Media";

export default function StoryPickerDialog(props) {
  const { onClose, onPick = () => {} } = props;

  const { t } = useTranslation();

  const { loading, stories, loadStories } = useContext(ContentContext);

  useEffect(loadStories, []);

  const [visible, setVisible] = useState(false);

  // animate appear
  useEffect(() => {
    const escapeKey = (evt) => {
      if (evt.key == "Escape") handleClose(evt);
    };
    setTimeout(() => setVisible(true), 50);
    window.addEventListener("keydown", escapeKey);
    return () => window.removeEventListener("keydown", escapeKey);
  }, []);

  const handleClose = (evt) => {
    evt?.preventDefault();
    setVisible(false);
    setTimeout(onClose, 150);
  };

  const handlePick = (storyId) => {
    onPick(storyId);
    handleClose();
  };

  return createPortal(
    <div
      className={`fixed inset-0 flex flex-col items-center justify-center p-4 select-none transition-all ${
        visible ? "pointer-events-auto" : "pointer-events-none"
      }`}
      style={{
        zIndex: 1100,
      }}
    >
      <div
        className={`absolute inset-0 bg-black/20 transition-opacity ${
          visible ? "opacity-100" : "opacity-0"
        }`}
        onClick={handleClose}
      />
      <div
        className={`w-auto bg-white shadow-lg rounded-xl overflow-hidden relative transition-opacity ${
          visible ? "opacity-100" : "opacity-0"
        }`}
      >
        <div className="max-h-[600px] flex flex-col overflow-hidden">
          <div className="pt-4 px-4 pb-3 flex items-center justify-between border-b">
            <div className="font-medium text-lg">
              {t("content.stories.pick_story")}
            </div>
            {loading ? (
              <div className="p-2">
                <Loader strokeWidth={8} width={20} />
              </div>
            ) : (
              <button className="p-2" onClick={loadStories}>
                <ArrowPathIcon className={`w-5 text-dark-gray`} />
              </button>
            )}
          </div>
          <div className="flex-grow overflow-y-auto">
            <div className="p-4 grid grid-cols-3 gap-1">
              {stories.map((story) => (
                <div
                  key={story.id}
                  className="flex-shrink-0 w-48 cursor-pointer"
                  onClick={() => handlePick(story.id)}
                >
                  <Media src={story.media_url} format="story" />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.body,
  );
}
