import _, { debounce, sortBy } from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import Textarea from "react-expanding-textarea";

import Button from "~/components/elements/Button";

import axios from "axios";
import { InboxContext } from "~/contexts/inbox-context";
import { UserContext } from "~/contexts/user-context";
import ConversationHeader from "./ConversationHeader";

import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { appInset } from "~/utils/environment";
import MenuItem from "../profile/MenuItem";
import Loader from "~/components/utils/Loader";
import ContactInformation from "../contacts/ContactInformation";
import ScreenSlide from "~/components/utils/ScreenSlide";
import ContactAllMedia from "../contacts/ContactAllMedia";
import ContactInsights from "../contacts/ContactInsights";
import SavedMessages from "../contacts/SavedMessages";

export default function ConversationDetails(props) {
  const { conversation, messages = [], back } = props;

  const { contact } = conversation;

  const { organization } = useContext(UserContext);
  const { updateContact, refreshContactInfo } = useContext(InboxContext);

  const { t } = useTranslation();

  // Load contact
  useEffect(() => refreshContactInfo(contact.id), [contact.id]);

  // Pages
  const { path } = useParams();
  const pages = {
    information: <ContactInformation conversation={conversation} />,
    media: <ContactAllMedia conversation={conversation} messages={messages} />,
  };

  // Notes
  const noteInputRef = useRef(null);
  const [saving, setSaving] = useState(false);
  const handleSave = () => {
    setSaving(true);
    const notes = noteInputRef.current.value;
    updateContact(contact.id, { notes });
    _.delay(() => setSaving(false), 500);
  };
  const handleChange = debounce((evt) => {
    const notes = evt.target.value;
    updateContact(contact.id, { notes });
  }, 400);

  // sync this conv only
  const handleSync = () => {
    axios.get(`/api/inbox/conversations/${conversation.id}/sync/`);
  };

  return (
    <>
      <div
        className={`w-full h-full sm:relative overflow-visible flex flex-col`}
        style={{
          paddingTop: appInset.top,
        }}
      >
        <ConversationHeader
          conversation={conversation}
          back={back}
          className="flex-shrink-0"
        >
          {saving ? (
            <Loader width={20} strokeWidth={8} className="my-1 mx-2" />
          ) : (
            <button
              className="flex items-center py-1 text-md px-4"
              onClick={handleSave}
            >
              {t("shared.save")}
            </button>
          )}
        </ConversationHeader>
        <div className="flex-grow overflow-auto scrollbar-hide pb-24">
          {/* Contact information */}
          <MenuItem
            label={t("inbox.details.information")}
            to={`information`}
            className={"!border-t-0"}
          />
          {/* ALl shared media */}
          <MenuItem label={t("inbox.details.media")} to={`media`} />
          {/* Notes */}
          <div className="p-4 sm:px-6 text-md border-t">
            {t("inbox.details.notes")}
          </div>
          <Textarea
            className="w-full h-auto [min-height:120px] [max-height:240px] pb-4 px-4 sm:px-6 border-none outline-none focus:ring-transparent focus:border-none resize-none placeholder:text-dark-gray text-sm bg-transparent"
            ref={noteInputRef}
            defaultValue={contact.notes}
            onChange={handleChange}
            placeholder={t("inbox.details.empty_notes")}
          />
          {/* Insights */}
          <ContactInsights contact={contact} />
          {/* Sync */}
          <div className="p-4 sm:p-6">
            {organization.dev && (
              <Button
                label="Sync conversation (dev)"
                className="w-full text-base mt-4 sm:mt-6"
                onClick={handleSync}
              />
            )}
          </div>
        </div>
      </div>
      {/* Pages */}
      <ScreenSlide visible={!!pages[path]} stackDepth={3} hideTabBar>
        {pages[path]}
      </ScreenSlide>
    </>
  );
}
