import { ClockIcon } from "@heroicons/react/24/solid";
import { delay } from "lodash";
import React, { useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FlowContext } from "~/contexts/flow-context";
import CustomNode from "./CustomNode";
import Input from "~/components/elements/Input";

export default function DelayNode(props) {
  const { t } = useTranslation();

  const { selected, data } = props;
  const { id, options } = data;

  const { delay_unit, delay_duration, autoFocus } = options;

  const { editAction } = useContext(FlowContext);

  const handleDurationChange = (value) => {
    editAction(id, {
      options: { ...options, delay_duration: parseInt(value) },
    });
  };

  const handleUnitChange = (evt) => {
    editAction(id, {
      options: { ...options, delay_unit: evt.target.value },
    });
  };

  return (
    <CustomNode selected={selected}>
      <div
        className={`py-3 px-3 bg-white border-2 rounded-2xl nodrag ring-1 ring-indigo-400 ring-offset-1 ring-opacity-0 hover:ring-opacity-100 transition-all flex space-x-2 items-center text-md whitespace-nowrap ${
          selected ? "ring-opacity-100 z-50" : null
        }`}
      >
        <div className="w-8 h-8 p-2 bg-yellow-500 text-dark-yellow-500 rounded-2xl">
          <ClockIcon className="w-4" />
        </div>

        <div className="text-sm">
          {t("automation.scenarios.actions.delay.wait")}
        </div>

        <Input
          value={delay_duration}
          onChange={handleDurationChange}
          type="number"
          autoFocus={autoFocus}
          className="max-w-[50px]"
        />

        <select
          className="input-select"
          value={delay_unit}
          onChange={handleUnitChange}
        >
          {["seconds", "minutes", "hours", "days"].map((unit) => (
            <option key={unit} value={unit}>
              {t("time." + unit)}
            </option>
          ))}
        </select>
      </div>
    </CustomNode>
  );
}
