import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import FolderPickerField from "../../../inbox/folders/FolderPickerField";
import { FlowContext } from "~/contexts/flow-context";

export default function FolderOptions(props) {
  const { t } = useTranslation();

  const { trigger } = props;

  const { editTriggerOptions } = useContext(FlowContext);

  const handlePick = (folderId) => {
    editTriggerOptions(trigger.id, { folder_id: folderId });
  };

  return (
    <>
      <div className="flex items-center space-x-2">
        <div className="text-md">
          {t(`automation.scenarios.trigger_type.folder.select_folder`)}
        </div>
        <FolderPickerField
          value={trigger.options?.folder_id}
          onPick={handlePick}
        />
      </div>
    </>
  );
}
