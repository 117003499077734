import React from "react";
import { useTranslation } from "react-i18next";

import ActionTitle from "../automation/scenarios/executions/ActionTitle";
import ConversionLinkAction from "./actions/ConversionLinkAction";
import FolderAction from "./actions/FolderAction";
import MediaMessageAction from "./actions/MediaMessageAction";
import MessageAction from "./actions/MessageAction";
import ShareMediaAction from "./actions/ShareMediaAction";
import SurveyAction from "./actions/SurveyAction";
import SurveyResults from "../automation/surveys/SurveyResults";

const previewComponents = {
  message: MessageAction,
  conversion_link: ConversionLinkAction,
  folder: FolderAction,
  share_media: ShareMediaAction,
  media_message: MediaMessageAction,
  survey: SurveyAction,
};

export default function ActionResults(props) {
  const { t } = useTranslation();

  const { action } = props;

  const PreviewComponent = previewComponents[action.action_type];

  return (
    <div className="flex items-start space-x-6">
      <div className={"w-80 bg-white rounded-lg p-3 border space-y-3"}>
        <div className="flex items-center justify-between">
          <div className="text-sm font-medium">
            <ActionTitle action={action} />
          </div>
        </div>
        {PreviewComponent ? <PreviewComponent action={action} /> : null}
      </div>
      <div className="flex flex-col items-start space-y-3">
        <div className="flex space-x-4 items-start">
          {action?.metrics && (
            <div className="bg-white rounded-lg border flex divide-x">
              {Object.keys(action.metrics).map((metric) => {
                if (action.metrics[metric] == null) return null;
                return (
                  <div key={metric} className="px-3 py-2 w-24 space-y-0.5">
                    <div className="text-xs font-medium leading-6 text-neutral-500">
                      {t("campaigns.metrics." + metric)}
                    </div>
                    <div className="text-base font-medium leading-6 text-neutral-900">
                      {action.metrics[metric]}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          <div className="space-y-2">
            {action.running > 0 && (
              <div className="px-2.5 py-1 bg-yellow-300 text-yellow-800 border rounded-lg">
                <div className="text-xs font-medium leading-5">
                  {t("campaigns.metrics.running")}: {action.running}
                </div>
              </div>
            )}
            {action.failed > 0 && (
              <div className="px-2.5 py-1 bg-red-400 text-white border rounded-lg">
                <div className="text-xs font-medium leading-5">
                  {t("campaigns.metrics.failed")}: {action.failed}
                </div>
              </div>
            )}
          </div>
        </div>
        {action.action_type === "survey" && (
          <div className="bg-white rounded-lg border p-3">
            <SurveyResults options={action.results} />
          </div>
        )}
      </div>
    </div>
  );
}
